import { format, parseISO } from 'date-fns'

const formattingDate = date => {
  if (date) {
    const _date = parseISO(date) || new Date()
    return format(_date, 'dd/MM/yyyy')
  }
}

const formattingDateMessage = date => {
  if (date) {
    const _date = parseISO(date) || new Date()
    return isToday(_date) ? format(_date, 'HH:mm') : format(_date, 'dd/MM/yyyy')
  }
}

const isToday = someDate => {
  const today = new Date()
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  )
}

export { formattingDate, formattingDateMessage }

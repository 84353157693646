import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Accueil'
      }
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '../views/Chat.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Tous les messages',
        isBack: true
      },
      parent: '/'
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/Registration.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/new-project',
    name: 'NewProject',
    component: () =>
      import(/* webpackChunkName: "new-project" */ '../views/NewProject.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Nouveau projet',
        isBack: true
      }
    }
  },
  {
    path: '/edit-project/:projectId',
    name: 'EditProject',
    component: () =>
      import(/* webpackChunkName: "edit-project" */ '../views/EditProject.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Modifier le projet',
        isBack: true
      },
      parent: '/projects'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () =>
      import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Projets',
        search: true
      }
    }
  },
  {
    path: '/project/:projectId',
    name: 'Project',
    component: () =>
      import(/* webpackChunkName: "project" */ '../views/Project.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: '',
        description: '',
        isBack: true,
        toggle: true,
        edit: true
      },
      parent: '/projects'
    }
  },
  {
    path: '/review-information/:projectId',
    name: 'ReviewInformation',
    component: () =>
      import(
        /* webpackChunkName: "review-information" */ '../views/ReviewInformation.vue'
      ),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Vérifier les informations',
        description: 'Vérification par entité',
        isBack: true
      },
      parent: '/projects'
    }
  },
  {
    path: '/uploaded-materials/:projectId',
    name: 'UploadedMaterials',
    component: () =>
      import(
        /* webpackChunkName: "materials" */ '../views/UploadedMaterials.vue'
      ),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Sources du projet',
        isBack: true
      },
      parent: '/projects'
    }
  },
  {
    path: '/created-documents/:projectId',
    name: 'CreatedDocuments',
    component: () =>
      import(
        /* webpackChunkName: "created-document" */ '../views/CreatedDocuments.vue'
      ),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Documents crées',
        isBack: true
      },
      parent: '/projects'
    }
  },
  {
    path: '/templates',
    name: 'Templates',
    component: () =>
      import(/* webpackChunkName: "template" */ '../views/Templates.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Modèles',
        search: true
      }
    }
  },
  {
    path: '/new-template',
    name: 'NewTemplate',
    component: () =>
      import(/* webpackChunkName: "new-template" */ '../views/NewTemplate.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Créer un nouveau modèle',
        isBack: true
      },
      parent: '/templates'
    }
  },
  {
    path: '/edit-template/:modelId',
    name: 'EditTemplate',
    component: () =>
      import(
        /* webpackChunkName: "edit-template" */ '../views/EditTemplate.vue'
      ),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Modifier un modèle',
        isBack: true
      },
      parent: '/templates'
    }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () =>
      import(/* webpackChunkName: "documents" */ '../views/Documents.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Documents',
        search: true
      }
    }
  },
  {
    path: '/document/:reportId',
    name: 'EditDocument',
    component: () =>
      import(
        /* webpackChunkName: "edit-document" */ '../views/EditDocument.vue'
      ),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Document',
        isBack: true,
        showToggleReportStatus: true
      },
      parent: '/documents'
    }
  },
  {
    path: '/pdf/:documentId',
    name: 'PDF',
    component: () => import(/* webpackChunkName: "pdf" */ '../views/PDF.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        defaultTitle: 'Vérifier les informations',
        description: 'Vérification par document',
        isBack: true
      },
      parent: '/projects'
    }
  },
  {
    path: '/new-document',
    name: 'NewDocument',
    component: () =>
      import(/* webpackChunkName: "new-document" */ '../views/NewDocument.vue'),
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/settings',
    name: 'Réglages',
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Réglages'
      }
    }
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: () =>
      import(
        /* webpackChunkName: "user-management" */ '../views/UserManagement.vue'
      ),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Gérer les utilisateurs',
        isBack: true,
        search: true
      },
      parent: '/settings'
    }
  },
  {
    path: '/new-user',
    name: 'NewUser',
    component: () =>
      import(/* webpackChunkName: "new-user" */ '../views/NewUser.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Ajouter un utilisateur',
        isBack: true
      },
      parent: '/settings'
    }
  },
  {
    path: '/user/:userId',
    name: 'EditUser',
    component: () =>
      import(/* webpackChunkName: "edit-user" */ '../views/EditUser.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Modifier un utilisateur',
        isBack: true
      },
      parent: '/settings'
    }
  },
  {
    path: '/informations-management',
    name: 'InformationsManagement',
    component: () =>
      import(
        /* webpackChunkName: "information" */ '../views/InformationsManagement.vue'
      ),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Hide informations management ',
        isBack: true,
        search: true
      },
      parent: '/settings'
    }
  },
  {
    path: '/information/:name',
    name: 'EditInformation',
    component: () =>
      import(
        /* webpackChunkName: "edit-information" */ '../views/EditInformation.vue'
      ),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Hide informations management ',
        isBack: true
      },
      parent: '/settings'
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'FAQs',
        isBack: true
      },
      parent: '/settings'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () =>
      import(/* webpackChunkName: "policy" */ '../views/PrivacyPolicy.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Privacy policy',
        isBack: true
      },
      parent: '/settings'
    }
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () =>
      import(/* webpackChunkName: "terms" */ '../views/TermsOfUse.vue'),
    meta: {
      layout: 'auth-layout',
      data: {
        title: 'Terms of use',
        isBack: true
      },
      parent: '/settings'
    }
  },
  {
    path: '*',
    name: 'Error',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const isAuthAsync = async () => {
  try {
    await Auth.currentAuthenticatedUser({ bypassCache: true })
    await Auth.currentSession()
    return true
  } catch (err) {
    return false
  }
}

const names = ['Login', 'Registration', 'ForgotPassword']

// authorization check
router.beforeEach(async (to, from, next) => {
  const isResolvedName = names.find(item => item === to.name)
  const isAuth = await isAuthAsync()

  if (to.name === 'Registration' && to.params.isCanOpen !== 'yes')
    next({ name: 'Login' })
  if (!isResolvedName && !isAuth) next({ name: 'Login' })
  if (isResolvedName && isAuth) next({ name: 'Home' })
  next()
})

export default router

import { format } from 'date-fns'

export const defaultNewInfo = {
  entityId: '',
  dataLabel: '',
  dataValue: '',
  sourceDocumentType: '',
  sourceDocumentDate: format(new Date(), 'dd/MM/yyyy')
}

export const defaultFilter = {
  nextToken: {},
  filterDataCodes: [],
  filterDataSources: [],
  filterDataDocumentYears: [],
  filterDataConfidences: []
}

const state = {
  // state all projects
  allProjects: [],
  loadingAllProjects: true,
  errorAllProjects: '',

  // state last projects
  lastProjects: [],
  loadingLastProjects: true,
  errorLastsProjects: '',

  // options for new project
  departments: [],
  loadingDepartments: true,
  errorDepartments: '',
  lawyers: [],
  loadingLawyers: true,
  errorLawyers: '',

  // state new project
  isSendingNewProject: false,
  errorSendingNewProject: '',
  isSendingNewProjectDocuments: false,
  errorSendingNewProjectDocuments: '',
  projectName: '',

  // state entities project
  entitiesProjectIdsList: [],
  entitiesProject: [],
  loadingEntitiesProject: true,
  errorEntitiesProject: '',
  sendingEntity: false,
  errorSendingEntity: '',
  newInfo: { ...defaultNewInfo },
  currentNewInfoId: '',
  sendingNewInfo: false,
  errorSendingNewInfo: '',
  entitiesData: [],
  loadingEntitiesData: false,
  errorEntitiesData: '',
  removingEntityItem: false,
  errorRemovingEntityItem: '',
  putEntityItem: false,
  errorPutEntityItem: '',

  // state project
  project: null,
  loadingProject: true,
  errorProject: '',

  // state project status
  loadingProjectStatus: true,
  errorProjectStatus: '',

  filter: { ...defaultFilter },
  filterHandlerUpdateStatus: false,
  filterNextTokens: [],
  filterDataCodes: [],
  filterDataSources: [],
  filterDataDocumentYears: [],
  filterDataConfidences: [],
  filterDataEntityName: [],
  fetchingFilters: false,
  errorFetchingFilters: '',

  removingProject: false,
  errorRemovingProject: '',

  putProject: false,
  errorPutProject: '',

  search: '',

  placeholders: [],
  loadingPlaceholders: false,
  errorPlaceholders: '',

  analyses: [],
  loadingAnalyses: false,
  errorAnalyses: '',

  fetchAllData: false,
  closeEntities: false,
  openEntities: false
}

export default state

const state = {
  // state last documents
  lastDocuments: [],
  loadingLastDocuments: true,
  errorLastsDocuments: '',

  // state all documents
  documents: [],
  loadingDocuments: true,
  errorDocuments: '',

  // state document
  document: null,
  file: [],
  loadingDocument: true,
  errorDocument: '',

  // state models
  models: [],
  loadingModels: true,
  errorModels: '',

  // state last models
  modelsLast: [],
  loadingModelsLast: true,
  errorModelsLast: '',

  sendingDocument: false,
  errorSendingDocument: '',

  // state documents by project id
  documentsByProjectId: [],
  loadingDocumentsByProjectId: true,
  errorDocumentsByProjectId: '',

  // state materials by project id
  materialsByProjectId: [],
  loadingMaterialsByProjectId: true,
  errorMaterialsByProjectId: '',

  removingMaterial: false,
  errorRemovingMaterial: '',

  sendingModel: false,
  errorSendingModel: '',

  // state simple model
  model: [],
  loadingModel: true,
  errorModel: '',

  isActiveCreateDocument: false,

  reviewDocument: null,
  loadingReviewDocument: true,
  errorReviewDocument: false,

  download: false,
  errorDownload: '',

  removingModel: false,
  errorRemovingModel: '',

  report: [],
  loadingReport: true,
  errorReport: '',

  removingReport: false,
  errorRemovingReport: '',

  sendingRepost: false,
  errorSendingReport: '',

  reportStatus: '',
  loadingReportStatus: false,
  errorReportStatus: '',

  search: '',

  namePDF: '',
  isBlockPage: false,
  analyseEntityList: [],

  s3Url: {},

  mailmergeStatus: 'DONE',

  deleteStatus: false
}

export default state

const state = {
  placedStatus: false,
  docFocused: false,
  s3Url: {},
  entityListDublicate: [],
  reportName: null,
  showNewFields: false,
  documentType: '',
  entityId: '',
  oldLabel: '',
  newEntityFields: [],
  reportDataResponse: [],
  newReportData: [],
  entityList: [],
  listPlaceholders: [],
  singleEntity: {},
  stateEdit: false,
  search: '',
  documentEntities: [],
  indexEditingEntity: 0,
  entityStatus: 'read',
  errorEntityName: false,
  showUnsavedChanges: {
    status: false,
    payload: {}
  },
}

export default state

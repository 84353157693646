import Vue from 'vue'
const mutations = {
  setShowUnsavedChanges(state, { status, payload }) {
    state.showUnsavedChanges = { status, payload }
  },
  setErrorEntityName(state, status) {
    state.errorEntityName = status
  },
  clearStateEdit(state) {
    state.stateEdit = false
  },
  setEntityStatus(state, status) {
    state.entityStatus = status
  },
  setNewEntityFields(state, res) {
    const newEntityFields = []
    for (const prop of res) {
      const newItem = Object.assign({}, prop)
      newItem.dataValues = []
      newItem.dataValues.push(prop.dataValues.find(a => a.dataDefault === true))
      newEntityFields.push(newItem)
    }
    Vue.set(state, 'newEntityFields', newEntityFields)
  },
  setListPlaceholders(state, { items }) {
    state.listPlaceholders = JSON.parse(JSON.stringify(items))
  },
  setSingleEntity(state, item) {
    state.singleEntity = item
  },
  setEntityList(state, arr) {
    const newEntityList = [...state.entityList]
    newEntityList.push(arr)
    state.entityList = newEntityList
  },
  setEntityListNewArray(state, arr) {
    state.entityList = arr
  },
  removeSelected(state, index) {
    state.singleEntity.fields.splice(index, 1)
    for (const prop of state.entityList) {
      if (prop.status === 'edit') {
        prop.fields = state.singleEntity.fields
        return
      }
    }
  },
  setSingleEntityName(state, name) {
    state.singleEntity.name = name
  },
  saveEntity(state, project_state) {
    for (const prop of state.entityList) {
      if (project_state) {
        prop.withNewFields = project_state
      }
      prop.status = 'read-only'
    }
    state.singleEntity = {}
    state.stateEdit = false
  },
  editEntity(state, index) {
    state.stateEdit = true
    state.entityList[index].status = 'edit'
    state.singleEntity = state.entityList[index]
    state.indexEditingEntity = index
  },
  clearSingleEntity(state) {
    state.stateEdit = false
    state.singleEntity = {}
  },
  clearSingleEntityFields(state) {
    state.singleEntity.fields = []
  },
  setSingleEntityPlaceholder(state, name) {
    state.singleEntity.placeholder = name
    for (const prop of state.entityList) {
      if (prop.status === 'edit') {
        prop.placeholder = name
        return
      }
    }
  },
  deleteEntity(state) {
    state.stateEdit = false
    let index = null
    for (const prop in state.entityList) {
      if (state.entityList[prop].status === 'edit') {
        index = parseInt(prop)
      }
    }
    state.entityList.splice(index, 1)
    state.singleEntity = {}
  },
  setReportDataResponse(state, val) {
    state.reportDataResponse = val
  },
  setFetchEntityList(state, res) {
    const newEntityList = []
    const data = res.modelPlaceholders || res.reportData || res.reportEntityData
    for (const prop of data) {
      const newObj = {}
      newObj.name = prop.entityValue || prop.entityLabel
      newObj.label = prop.entityLabel || prop.entityLabel
      newObj.id = res.modelPlaceholders
        ? res.modelPlaceholders.indexOf(prop)
        : prop.entityId
      newObj.search = null
      newObj.type = null
      newObj.code = prop.entityCode
      newObj.status = 'read-only'
      newObj.fields = []
      for (const field of prop.entityData) {
        // debugger
        const newObjFields = {}
        if (res.reportData) {
          newObjFields.dataCode = field.dataCode
          newObjFields.dataId = field.dataId
          if (field.dataConfidence) {
            newObjFields.dataConfidence = field.dataConfidence
          }
          newObjFields.dataLabel = field.dataLabel
          newObjFields.dataValue = field.dataValue
          newObjFields.editable = false
          newObjFields.locked = field.locked
          newObjFields.template = false
          newObjFields.dataYear = field.dataYear
        }
        if (res.modelPlaceholders || field.dataId === null) {
          newObjFields.code = field.dataCode
          newObjFields.isPlaced = field.dataIsPlaced
          newObjFields.pages = field.dataPage
          newObjFields.positions = []
          newObjFields.text = field.dataLabel
          newObjFields.value = field.dataCode
          newObjFields.dataYear = field.dataYear
          newObjFields.template = true
        }
        newObj.fields.push(newObjFields)
      }
      newEntityList.push(newObj)
    }
    state.entityList = newEntityList
  },
  setSearch(state, message) {
    state.search = message
  },
  setDocumentEntities(state, res) {
    state.documentEntities = res
  },
  setDocumentType(state, name) {
    state.documentType = name
  },
  setReportData(state, entity) {
    state.reportData = entity
  },
  clearOldFields(state) {
    for (const prop of state.entityList) {
      if (prop.status === 'edit') {
        prop.fields = []
        return
      }
    }
  },
  setShowNewFields(state, val) {
    state.showNewFields = val
  },
  setReportName(state, name) {
    state.reportName = name
  },
  setEditableStateForPlaceholder(
    state,
    { indexEntity, indexPlaceholder, flag }
  ) {
    const entityList = [...state.entityList]
    entityList[indexEntity].fields[indexPlaceholder].editable = flag
    Vue.set(state, 'entityList', entityList)
  },
  setEditableDataForPlaceholder(state, [index_entity, index_placeholder, val]) {
    state.entityList[index_entity].fields[index_placeholder].dataValue = val
    state.entityList[index_entity].fields[index_placeholder].editable = false
    state.entityList[index_entity].fields[index_placeholder].locked = true
  },
  setEntityId(state, id) {
    state.entityId = id
  },
  setOldLabel(state, label) {
    state.oldLabel = label
  },
  setEntityDublicate(state, entityList) {
    state.entityListDublicate = JSON.parse(JSON.stringify(entityList))
  },
  setOldEntityList(state, oldList) {
    state.entityList = oldList
  },
  setS3Url(state, val) {
    state.s3Url = val
  },
  setIdInEntityItem(state, [index, id]) {
    state.entityList[index].id = id
    state.entityList[index].dataId = id
  },
  setPlacedStatus(state, status) {
    state.placedStatus = status
  },
  setDocFocused(state, status) {
    state.docFocused = status
  },
  setPlacedStatusInField(state, [itemIndex, fieldIndex, status]) {
    state.entityList[itemIndex].fields[fieldIndex].placedStatus = status
  }
}

export default mutations

import { API } from 'aws-amplify'
import { getTime } from 'date-fns'

const apiName = 'bylawAPI'

const actions = {
  // actions all projects
  async fetchAllProjects({ commit }) {
    commit('setLoadingAllProjects', { status: true })
    commit('setErrorAllProjects', { message: '' })
    commit('setAllProjects', { items: [] })

    try {
      const response = await API.get(apiName, '/project')
      const projects = response.projects
        .filter(project => project.projectStatus !== 'DELETED')
        .sort((a, b) => {
          const xFormat = getTime(new Date(a.updatedOn))
          const yFormat = getTime(new Date(b.updatedOn))

          return xFormat > yFormat ? -1 : xFormat < yFormat ? 1 : 0
        })

      commit('setAllProjects', { items: projects })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorAllProjects', { message })
    } finally {
      commit('setLoadingAllProjects', { status: false })
    }
  },

  // actions last projects
  async fetchLastProjects({ commit }) {
    commit('setLoadingLastProjects', { status: true })
    commit('setErrorLastProjects', { message: '' })
    commit('setLastProjects', { items: [] })

    try {
      const response = await API.get(apiName, '/project/last')
      const projects = response.projects
        .filter(project => project.projectStatus !== 'DELETED')
        .sort((a, b) => {
          const xFormat = getTime(new Date(a.updatedOn))
          const yFormat = getTime(new Date(b.updatedOn))

          return xFormat > yFormat ? -1 : xFormat < yFormat ? 1 : 0
        })

      commit('setLastProjects', { items: projects })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorLastProjects', { message })
    } finally {
      commit('setLoadingLastProjects', { status: false })
    }
  },

  // actions departments
  async fetchDepartments({ commit }) {
    commit('setLoadingDepartments', { status: true })
    commit('setErrorDepartments', { message: '' })
    commit('setDepartments', { items: [] })

    try {
      const response = await API.get(apiName, '/user/department')

      response.departments = response.departments.map(department => {
        department.value = department.departmentId
        department.label = department.departmentName
        return department
      })

      response.departments = response.departments.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })

      commit('setDepartments', { items: response.departments })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorDepartments', { message })
    } finally {
      commit('setLoadingDepartments', { status: false })
    }
  },

  // actions lawyers
  async fetchLawyers({ commit }) {
    commit('setLoadingLawyers', { status: true })
    commit('setErrorLawyers', { message: '' })
    commit('setLawyers', { items: [] })

    try {
      const response = await API.get(apiName, '/user')

      response.users = response.users.map(user => {
        user.value = user.userId
        user.label = `${user.firstName} ${user.lastName}`
        return user
      })

      response.users = response.users.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })

      commit('setLawyers', { items: response.users })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorLawyers', { message })
    } finally {
      commit('setLoadingLawyers', { status: false })
    }
  },

  // actions new project
  async sendNewProject({ commit }, data) {
    commit('setIsSendingNewProject', { status: true })
    commit('setErrorSendingNewProject', { message: '' })

    try {
      const response = await API.post(apiName, `/project`, {
        body: { ...data }
      })

      return response.projectId
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingNewProject', { message })
    } finally {
      commit('setIsSendingNewProject', { status: false })
    }

    return null
  },

  async sendNewProjectDocuments({ commit }, data) {
    commit('setIsSendingNewProjectDocuments', { status: true })
    commit('setErrorSendingNewProjectDocuments', { message: '' })

    try {
      return await API.post(apiName, `/document/url/upload`, {
        body: { ...data }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingNewProjectDocuments', { message })
    }

    return null
  },

  handlerSetIsSendingNewProjectDocuments({ commit }, { status }) {
    commit('setIsSendingNewProjectDocuments', { status })
  },

  setProjectName({ commit }, projectName) {
    commit('setCreateProjectName', { projectName })
  },

  async uploadDocumentToS3({ commit }, { s3UrlObject, file }) {
    commit('setErrorSendingNewProjectDocuments', { message: '' })
    const { s3Url } = s3UrlObject

    try {
      const formData = new FormData()
      formData.append('file', file)

      await fetch(s3Url, { method: 'PUT', body: formData })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingNewProjectDocuments', { message })
    }

    return null
  },

  // actions entities project
  async fetchEntitiesProject({ commit }, { projectId, entitiesId = [] }) {
    commit('setLoadingEntitiesProject', { status: true })
    commit('setErrorEntitiesProject', { message: '' })
    
    try {
      const response = await API.get(apiName, `/analyse/entity/${projectId}`)

      if (entitiesId.length) {
        response.entities = response.entities.filter(item =>
          entitiesId.includes(item.entityId)
        )
      }

      commit('setEntitiesProject', { items: response.entities })

      if (entitiesId.length) return

      const entitieIds = response.entities.map(item => item.entityId)
      commit('setEntitiesProjectIdsList', { ids: entitieIds })
    } catch (err) {
      if (err.response.status === 404) {
        commit('setEntitiesProject', { items: [] })
        return
      }
      const { message } = err.response.data.error
      commit('setErrorEntitiesProject', { message })
    } finally {
      commit('setLoadingEntitiesProject', { status: false })
    }
  },

  resetEntitiesProject({ commit }) {
    commit('setLoadingEntitiesProject', { status: true })
    commit('setEntitiesProject', { items: [] })
  },

  handlerSetEntitiesProject({ commit }, { items }) {
    commit('setEntitiesProject', { items })
  },

  endLoadingEntitiesProject({ commit }) {
    commit('setLoadingEntitiesProject', { status: false })
  },

  async addNewEntity({ commit }, data) {
    commit('setSendingEntity', { status: true })
    commit('setErrorSendingEntity', { message: '' })

    const { projectId, ...body } = data

    try {
      await API.post(apiName, `/analyse/entity/${projectId}`, {
        body: { ...body }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingEntity', { message })
    } finally {
      commit('setSendingEntity', { status: false })
    }
  },

  setCurrentNewInfoId({ commit }, id) {
    commit('setCurrentNewInfoId', { id })
  },

  clearNewInfo({ commit }) {
    commit('clearNewInfo')
  },

  async addNewInfo({ commit }, data) {
    commit('setSendingNewInfo', { status: true })
    commit('setErrorSendingNewInfo', { message: '' })

    try {
      await API.post(apiName, `/analyse`, { body: { ...data } })
    } catch (err) {
      if (err.response.status === 404) return
      let message = null

      if (err.response.status === 400) {
        message = err?.response?.data?.detail || 'Une erreur est survenue'
      }

      if (!message) {
        message =
          err?.response?.data?.error?.message || 'Une erreur est survenue'
      }
      commit('setErrorSendingNewInfo', { message })
    } finally {
      commit('setSendingNewInfo', { status: false })
    }
  },

  async fetchEntitiesData({ commit, getters }, { entityId, filter }) {
    commit('setLoadingEntitiesData', { status: true })
    commit('setErrorEntitiesData', { message: '' })
    
    // const currentData = getters.getEntitiesData[entityId]?.pop() || []
    // commit('setEntitiesData', {
    //   items: { entityId, data: currentData }
    // })

    // const currentData = getters.getEntitiesData[entityId]?.pop() || []
    // commit('setEntitiesData', {
    //   items: { entityId, data: currentData }
    // })

    try {
      const response = await API.post(
        apiName,
        `/analyse/entity/data/${entityId}`,
        { body: { ...filter } }
      )

      let data = null

      if (response.data.length) {
        data = response.data.map(item => {
          const result = {}

          result.dataLabel = item.dataLabel
          result.isMoney = item.isMoney
          result.status = ''
          result.isEdit = false
          result.isChecked = false
          result.dataId = ''
          result.dataValue = ''
          result.sourceDocumentType = ''
          result.sourceDocumentDate = ''
          result.sourceDocumentId = ''
          result.variants = []

          if (item.dataValues && item.dataValues.length) {
            item.dataValues.forEach(value => {
              if (value.dataDefault) {
                result.status = value.dataConfidence
                result.dataId = value.dataId
                result.dataValue = value.dataValue
                result.sourceDocumentType = value.sourceDocumentType
                result.sourceDocumentDate = value.sourceDocumentDate
                result.sourceDocumentId = value.sourceDocumentId
              }

              result.variants.push({
                dataId: value.dataId,
                dataLabel: item.dataLabel,
                status: value.dataConfidence,
                dataValue: value.dataValue,
                dataDefault: value.dataDefault,
                sourceDocumentType: value.sourceDocumentType,
                sourceDocumentDate: value.sourceDocumentDate,
                sourceDocumentId: value.sourceDocumentId,
                isEdit: false,
                isChecked: false
              })
            })
          }
          return result
        })
      }
      commit('setEntitiesData', {
        items: data ? { entityId, data } : { entityId, data: [] }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const message =
        err?.response?.data?.error?.message || 'Une erreur est survenue'
      commit('setErrorEntitiesData', { message })
    } finally {
      commit('setLoadingEntitiesData', { status: false })
    }
  },

  setEntityData({ commit }, { entityId, data }) {
    commit('setEntitiesData', { items: { entityId, data: data } })
  },

  async removingEntityItem({ commit }, { entityId, dataId }) {
    commit('setRemovingEntityItem', { status: true })
    commit('setErrorRemovingEntityItem', { message: '' })

    try {
      await API.del(apiName, '/analyse', { body: { entityId, dataId } })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorRemovingEntityItem', { message })
    } finally {
      commit('setRemovingEntityItem', { status: false })
    }
  },

  async putEntityItem({ commit }, data) {
    commit('setPutEntityItem', { status: true })
    commit('setErrorPutEntityItem', { message: '' })

    try {
      await API.put(apiName, '/analyse', { body: { ...data } })
    } catch (err) {
      if (err.response.status === 404) return
      const message =
        err?.response?.data?.error?.message || 'Une erreur est survenue'
      commit('setErrorPutEntityItem', { message })
    } finally {
      commit('setPutEntityItem', { status: false })
    }
  },

  // actions project
  async fetchProject({ commit }, projectId) {
    commit('setLoadingProject', { status: true })
    commit('setErrorProject', { message: '' })
    commit('setProject', { item: [] })

    try {
      const result = await API.get(apiName, `/project/${projectId}`)

      commit('setProject', { item: result.project })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorProject', { message })
    } finally {
      commit('setLoadingProject', { status: false })
    }
  },

  async toggleProjectStatus({ commit }, data) {
    commit('setLoadingProjectStatus', { status: true })
    commit('setErrorProjectStatus', { message: '' })

    try {
      await API.put(apiName, `/project/status/${data.projectId}`, {
        body: { status: data.status }
      })

      commit('setProjectStatus', { status: data.status })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      const status = data.status === 'ONGOING' ? 'ARCHIVED' : 'ONGOING'
      commit('setProjectStatus', { status })
      commit('setErrorProjectStatus', { message })
    } finally {
      commit('setLoadingProjectStatus', { status: false })
    }
  },

  async renameEntity({ commit }, data) {
    commit('setSendingEntity', { status: true })
    commit('setErrorSendingEntity', { message: '' })

    try {
      return await API.put(apiName, `/analyse/entity`, {
        body: { ...data }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingEntity', { message })
    } finally {
      commit('setSendingEntity', { status: false })
    }
  },

  async removeEntity({ commit }, data) {
    commit('setSendingEntity', { status: true })
    commit('setErrorSendingEntity', { message: '' })

    try {
      await API.put(apiName, `/analyse/entity/remove`, {
        body: { ...data }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingEntity', { message })
    } finally {
      commit('setSendingEntity', { status: false })
    }
  },

  async fetchFiltersData({ commit }, projectId) {
    commit('setFetchingFilters', { status: true })
    commit('setErrorFetchingFilters', { message: '' })
    commit('setFilterDataCodes', { items: [] })
    commit('setFilterDataSources', { items: [] })
    commit('setFilterDataDocumentYears', { items: [] })
    commit('setFilterDataEntityName', { items: [] })
    commit('setFilterDataConfidences', { items: [] })

    try {
      const analysePlaceholder = await API.get(
        apiName,
        `/analyse/placeholder/filtered`
      )
      const analyseEntity = await API.get(
        apiName,
        `/analyse/entity/${projectId}`
      )

      // build filterDataCodes
      let filterDataCodes = analysePlaceholder.placeholders.map(item => {
        let result = {}

        if (item.categoryLabels && item.categoryLabels.length) {
          result = item.categoryLabels.map(label => {
            const data = {}
            data.name = label.dataLabel
            data.id = label.dataCode
            data.selected = false
            return data
          })
        }

        return result || []
      })

      if (filterDataCodes.length) {
        filterDataCodes = [].concat(...filterDataCodes)
      }

      // build filterDataSources
      const filterDataSources = analyseEntity.availableDocumentType.map(
        item => ({ name: item, id: item, selected: false })
      )

      // build filterDataDocumentYears
      let filterDataDocumentYears = analyseEntity.entities.reduce(
        (total, item) => {
          total.push(...item.availableYear)
          return total
        },
        []
      )
      filterDataDocumentYears = [...new Set(filterDataDocumentYears)].sort().reverse()
      filterDataDocumentYears = filterDataDocumentYears.map(item => ({
        name: item,
        id: item,
        selected: false
      }))

      // build filterDataEntityName
      const filterDataEntityName = analyseEntity.entities.map(item => ({
        name: item.entityName,
        id: item.entityId,
        selected: false
      }))

      // build filterDataConfidences
      const filterDataConfidences = {
        low: {
          status: true,
          id: 'LOW',
          label: 'Confiance moyenne'
        },
        high: {
          status: true,
          id: 'HIGH',
          label: 'Confiance élevée'
        },
        manually: {
          status: true,
          id: 'MANUAL',
          label: 'Informations vérifiées'
        }
      }

      commit('setFilterDataCodes', { items: filterDataCodes || [] })
      commit('setFilterDataSources', { items: filterDataSources || [] })
      commit('setFilterDataDocumentYears', {
        items: filterDataDocumentYears || []
      })
      commit('setFilterDataEntityName', { items: filterDataEntityName || [] })
      commit('setFilterDataConfidences', { items: filterDataConfidences || [] })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorFetchingFilters', { message })
    } finally {
      commit('setFetchingFilters', { status: false })
    }
  },

  updateFilter({ commit }, { filter }) {
    commit('setFilter', { filter })
  },

  setFilterHandlerUpdateStatus({ commit }, { status }) {
    commit('setFilterHandlerUpdateStatus', { status })
  },

  async removeProject({ commit }, { projectId }) {
    commit('setRemovingProject', { status: true })
    commit('setErrorRemovingProject', { message: '' })

    try {
      await API.del(apiName, `/project/${projectId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorRemovingProject', { message })
    } finally {
      commit('setRemovingProject', { status: false })
    }
  },

  async putProject({ commit }, { projectId, data }) {
    commit('setPutProject', { status: true })
    commit('setErrorPutProject', { message: '' })

    try {
      await API.put(apiName, `/project/${projectId}`, { body: { ...data } })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorPutProject', { message })
    } finally {
      commit('setPutProject', { status: false })
    }
  },

  setSearch({ commit }, { message }) {
    commit('setSearch', { message })
  },

  async fetchPlaceholders({ commit }) {
    commit('setLoadingPlaceholders', { status: true })
    commit('setErrorPlaceholders', { message: '' })

    try {
      const response = await API.get(apiName, '/analyse/placeholder')
      commit('setPlaceholders', { items: response.placeholders })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorPlaceholders', { message })
    } finally {
      commit('setLoadingPlaceholders', { status: false })
    }
  },

  async fetchAnalyses({ commit }) {
    commit('setLoadingAnalyses', { status: true })
    commit('setErrorAnalyses', { message: '' })

    try {
      const response = await API.get(apiName, '/analyse/entity')
      commit('setAnalyses', { items: response.entities })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorAnalyses', { message })
    } finally {
      commit('setLoadingAnalyses', { status: false })
    }
  },

  fetchAllData({ commit }, { status }) {
    commit('setFetchAllData', { status })
  },

  closeEntities({ commit }, { status }) {
    commit('setCloseEntities', { status })
  },

  openEntities({ commit }, { status }) {
    commit('setOpenEntities', { status })
  }
}

export default actions

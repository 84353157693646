import { API } from 'aws-amplify'

const apiName = 'bylawAPI'

const actions = {
  async addUser({ commit }, user) {
    commit('setSendingUser', { status: true })
    commit('setErrorSendingUser', { message: '' })

    try {
      await API.post(apiName, '/user', {
        body: { ...user }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const message = err?.response?.data?.error?.message || 'Une erreur est survenue'
      commit('setErrorSendingUser', { message })
    } finally {
      commit('setSendingUser', { status: false })
    }
  },

  async putUser({ commit }, data) {
    commit('setSendingUser', { status: true })
    commit('setErrorSendingUser', { message: '' })

    const { userId, ...user } = data

    try {
      await API.put(apiName, `/user/${userId}`, {
        body: { ...user }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const message = err?.response?.data?.error?.message || 'Une erreur est survenue'
      commit('setErrorSendingUser', { message })
    } finally {
      commit('setSendingUser', { status: false })
    }
  },

  async fetchCompanyData({ commit }) {
    try {
      const response = await API.get(apiName, '/user/company')
      commit('setCompanyData', { item: response })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorUsersManagement', { message })
    } finally {
      commit('setLoadingUsersManagement', { status: false })
    }
  },

  async fetchUsersManagement({ commit }) {
    commit('setLoadingUsersManagement', { status: true })
    commit('setErrorUsersManagement', { message: '' })

    try {
      const response = await API.get(apiName, '/user')

      const users = response.users.map(user => {
        user.full = `${user.firstName} ${user.lastName}`
        return user
      })

      commit('setUsersManagement', { items: users })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorUsersManagement', { message })
    } finally {
      commit('setLoadingUsersManagement', { status: false })
    }
  },

  async fetchUserById({ commit }, { userId, currentUser = false }) {
    commit('setLoadingUserById', { status: true })
    commit('setErrorUserById', { message: '' })

    !currentUser
      ? commit('setUserById', { item: [] })
      : commit('setCurrentUser', { item: null })

    try {
      const response = await API.get(apiName, `/user/${userId}`)

      !currentUser
        ? commit('setUserById', { item: response })
        : commit('setCurrentUser', { item: response })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorUserById', { message })
    } finally {
      commit('setLoadingUserById', { status: false })
    }
  },

  clearCurrentUser({ commit }) {
    commit('setCurrentUser', { item: null })
  },

  async fetchUserDepartments({ commit }) {
    commit('setLoadingUserDepartments', { status: true })
    commit('setErrorUserDepartments', { message: '' })
    commit('setUserDepartments', { items: [] })

    try {
      const response = await API.get(apiName, '/user/department')

      response.departments = response.departments.map(department => {
        department.value = department.departmentId
        department.label = department.departmentName
        return department
      })

      commit('setUserDepartments', { items: response.departments })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorUserDepartments', { message })
    } finally {
      commit('setLoadingUserDepartments', { status: false })
    }
  },

  async fetchUserPlaceholders({ commit }) {
    commit('setLoadingUserPlaceholders', { status: true })
    commit('setErrorUserPlaceholders', { message: '' })
    commit('setUserPlaceholders', { items: [] })

    try {
      const response = await API.get(apiName, '/user/placeholder')

      const result = response.placeholders.map(item => {
        const countShow = item.categoryLabels.filter(
          item => item.displayStatus === 'SHOW'
        )
        const information = `${countShow.length}/${item.categoryLabels.length}`

        return { information, ...item }
      })

      commit('setUserPlaceholders', { items: result })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorUserPlaceholders', { message })
    } finally {
      commit('setLoadingUserPlaceholders', { status: false })
    }
  },

  async sendUserPlaceholderStatus({ commit }, data) {
    commit('setSendingPlaceholderStatus', { status: true })
    commit('setErrorSendingPlaceholderStatus', { message: '' })

    try {
      await API.put(apiName, '/user/placeholder/batch', {
        body: { userPlaceholders: data }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data
      commit('setErrorSendingPlaceholderStatus', { message })
    } finally {
      commit('setSendingPlaceholderStatus', { status: false })
    }
  },

  setSearchUser({ commit }, { message }) {
    commit('setSearch', { message })
  }
}

export default actions

<template>
  <q-drawer
    v-model="drawer"
    show-if-above
    :mini="state"
    @mouseover="state = false"
    @mouseout="state = true"
    mini-to-overlay
    :width="215"
    :mini-width="86"
    :breakpoint="500"
    bordered
    content-class="bg-grey-3"
  >
    <q-scroll-area
      :class="{
        'fit menu': true,
        'disabled-pointer': isBlockPage
      }"
    >
      <q-list padding class="menu-list">
        <q-item clickable v-ripple @click="$router.push('/')"></q-item>

        <q-item
          v-for="(item, index) in menu"
          :key="index"
          :active="item.isActive"
          clickable
          v-ripple
        >
          <router-link :to="item.url">
            <q-item-section avatar>
              <span :class="item.logo"></span>
            </q-item-section>

            <q-item-section>
              {{ item.title }}
            </q-item-section>
          </router-link>
        </q-item>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import { mapState } from 'vuex'
const menu = [
  {
    logo: 'home',
    title: 'Accueil',
    isActive: true,
    url: '/'
  },
  {
    logo: 'folder-plus',
    title: 'Nouveau projet',
    isActive: false,
    url: '/new-project'
  },
  {
    logo: 'folder',
    title: 'Projets',
    isActive: false,
    url: '/projects'
  },
  {
    logo: 'file-text',
    title: 'Modèles',
    isActive: false,
    url: '/templates'
  },
  {
    logo: 'file-text-2',
    title: 'Documents',
    isActive: false,
    url: '/documents'
  },
  {
    logo: 'settings',
    title: 'Réglages',
    isActive: false,
    url: '/settings'
  }
]

export default {
  props: {
    menu: {
      type: Array,
      default: () => menu
    }
  },
  data() {
    return {
      drawer: false,
      state: true
    }
  },
  computed: {
    ...mapState('documents', ['isBlockPage'])
  },
  created() {
    this.setActiveMenu(this.$route)
  },
  methods: {
    closeMenu() {
      this.$emit('closeMenu')
    },
    setActiveMenu(to) {
      this.menu.map(item => (item.isActive = false))

      const items = this.menu.filter(item => {
        if (to.path.indexOf(item.url) > -1) {
          return item
        }
        if (to.meta && to.meta.parent) {
          return item.url === to.meta.parent
        }
      })
      items[items.length - 1].isActive = true
    }
  },
  watch: {
    $route(to) {
      this.setActiveMenu(to)

      if (window.innerWidth <= 500) {
        this.closeMenu()
      }
    }
  }
}
</script>

<style scoped>
.disabled-pointer {
  pointer-events: none;
}
</style>

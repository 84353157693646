<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('users', ['clearCurrentUser']),
    async logout() {
      await this.clearCurrentUser()
      await this.$Amplify.Auth.signOut()
      this.$router.push('/login')
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },
  watch: {
    $route() {
      document.title = 'Bylaw'
    },
    '$store.state.idleVue.isIdle': function(value) {
      if (value && this.layout === 'auth-layout') this.logout()
    }
  }
}
</script>

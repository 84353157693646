const mutations = {
  setAnalyseEntity(state, list) {
    state.analyseEntityList = list
  },
  setErrorAnalyseEntity(state, message) {
    state.errorAnalyseEntityList = message
  },
  setBlockPage(state, status) {
    state.isBlockPage = status
  },

  setPutEntityItem(state, { status }) {
    state.putEntityItem = status
  },
  setErrorPutEntityItem(state, { message }) {
    state.errorPutEntityItem = message
  },

  // mutations for last documents
  setLastDocuments(state, { items }) {
    state.lastDocuments = items
  },
  setLoadingLastDocuments(state, { status }) {
    state.loadingLastDocuments = status
  },
  setErrorLastDocuments(state, { message }) {
    state.errorLastsDocuments = message
  },

  // mutations for all documents
  setDocuments(state, { items }) {
    state.documents = items
  },
  setLoadingDocuments(state, { status }) {
    state.loadingDocuments = status
  },
  setErrorDocuments(state, { message }) {
    state.errorDocuments = message
  },

  // mutations for document
  setDocument(state, item) {
    state.document = item
  },
  setFile(state, { item }) {
    state.file = item
  },
  setLoadingDocument(state, { status }) {
    state.loadingDocument = status
  },
  setErrorDocument(state, { message }) {
    state.errorDocument = message
  },
  setS3Url(state, val) {
    state.s3Url = val
  },

  // mutations for models
  setModels(state, { items }) {
    state.models = items
  },
  setLoadingModels(state, { status }) {
    state.loadingModels = status
  },
  setErrorModels(state, { message }) {
    state.errorModels = message
  },

  // mutations for models last
  setModelsLast(state, { items }) {
    state.modelsLast = items
  },
  setLoadingModelsLast(state, { status }) {
    state.loadingModelsLast = status
  },
  setErrorModelsLast(state, { message }) {
    state.errorModelsLast = message
  },

  setSendingDocument(state, { status }) {
    state.sendingDocument = status
  },
  setErrorSendingDocument(state, { message }) {
    state.errorSendingDocument = message
  },

  // mutations documents by project id
  setDocumentsByProjectId(state, { items }) {
    state.documentsByProjectId = items
  },
  setLoadingDocumentsByProjectId(state, { status }) {
    state.loadingDocumentsByProjectId = status
  },
  setErrorDocumentsByProjectId(state, { message }) {
    state.errorDocumentsByProjectId = message
  },

  // mutations materials by project id
  setMaterialsByProjectId(state, { items }) {
    state.materialsByProjectId = items
  },
  setLoadingMaterialsByProjectId(state, { status }) {
    state.loadingMaterialsByProjectId = status
  },
  setErrorMaterialsByProjectId(state, { message }) {
    state.errorMaterialsByProjectId = message
  },

  setRemovingMaterial(state, { status }) {
    state.removingMaterial = status
  },
  setErrorRemovingMaterial(state, { message }) {
    state.errorRemovingMaterial = message
  },

  setSendingModel(state, { status }) {
    state.sendingModel = status
  },
  setErrorSendingModel(state, { message }) {
    state.errorSendingModel = message
  },

  // mutations for model
  setModel(state, { items }) {
    state.model = items
  },
  setLoadingModel(state, { status }) {
    state.loadingModel = status
  },
  setErrorModel(state, { message }) {
    state.errorModel = message
  },

  setIsActiveCreateDocument(state, { status }) {
    state.isActiveCreateDocument = status
  },

  setReviewDocument(state, { item }) {
    state.reviewDocument = item
  },
  setLoadingReviewDocument(state, { status }) {
    state.loadingReviewDocument = status
  },
  setErrorReviewDocument(state, { message }) {
    state.errorReviewDocument = message
  },

  setDownload(state, { status }) {
    state.download = status
  },
  setErrorDownload(state, { message }) {
    state.errorDownload = message
  },

  setRemovingModel(state, { status }) {
    state.removingModel = status
  },
  setErrorRemovingModel(state, { message }) {
    state.errorRemovingModel = message
  },

  setReport(state, { item }) {
    state.report = item
  },
  setLoadingReport(state, { status }) {
    state.loadingReport = status
  },
  setErrorReport(state, { message }) {
    state.errorReport = message
  },

  setRemovingReport(state, { status }) {
    state.removingReport = status
  },
  setErrorRemovingReport(state, { message }) {
    state.errorRemovingReport = message
  },

  setSendingReport(state, { status }) {
    state.sendingReport = status
  },
  setErrorSendingReport(state, { message }) {
    state.errorSendingReport = message
  },

  setReportStatus(state, { status }) {
    state.reportStatus = status
  },
  setLoadingReportStatus(state, { status }) {
    state.loadingReportStatus = status
  },
  setErrorReportStatus(state, { message }) {
    state.errorReportStatus = message
  },

  setSearch(state, { message }) {
    state.search = message
  },

  setNamePDF(state, { name }) {
    state.namePDF = name
  },

  setFetchEntityList(state, res) {
    const newEntityList = []
    for (const prop of res.modelPlaceholders
      ? res.modelPlaceholders
      : res.reportData) {
      const newObj = {}
      newObj.name = prop.entityValue || prop.entityLabel
      newObj.label = prop.entityLabel || prop.entityLabel
      newObj.id = res.modelPlaceholders
        ? res.modelPlaceholders.indexOf(prop)
        : prop.entityId
      newObj.search = null
      newObj.type = null
      newObj.code = prop.entityCode
      newObj.status = 'read-only'
      newObj.fields = []
      for (const field of prop.entityData) {
        const newObjFields = {}
        if (res.reportData) {
          newObjFields.dataCode = field.dataCode
          newObjFields.dataId = field.dataId
          if (field.dataConfidence) {
            newObjFields.dataConfidence = field.dataConfidence
          }
          newObjFields.dataLabel = field.dataLabel
          newObjFields.dataValue = field.dataValue
          newObjFields.editable = false
          newObjFields.locked = field.locked
          newObjFields.template = false
        }
        if (res.modelPlaceholders || field.dataId === null) {
          newObjFields.code = field.dataCode
          newObjFields.isPlaced = field.dataIsPlaced
          newObjFields.pages = field.dataPage
          newObjFields.positions = []
          newObjFields.text = field.dataLabel
          newObjFields.value = field.dataCode
          newObjFields.template = true
        }
        newObj.fields.push(newObjFields)
      }
      newEntityList.push(newObj)
    }
    state.entityList = newEntityList
  },

  setMailmergeStatus(state, status) {
    state.mailmergeStatus = status
  },

  setDeleteStatus(state, { status }) {
    state.deleteStatus = status
  }
  
}

export default mutations

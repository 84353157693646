const getters = {
  // getters all projects
  getAllProjects(state) {
    return state.allProjects
  },
  getLoadingAllProjects(state) {
    return state.loadingAllProjects
  },
  getErrorAllProjects(state) {
    return state.errorAllProjects
  },

  // getters last projects
  getLastProjects(state) {
    return state.lastProjects
  },
  getLoadingLastProjects(state) {
    return state.loadingLastProjects
  },
  getErrorLastProjects(state) {
    return state.errorLastsProjects
  },

  // getters options for new project
  getDepartments(state) {
    return state.departments
  },
  getLoadingDepartments(state) {
    return state.loadingDepartments
  },
  getErrorDepartments(state) {
    return state.errorDepartments
  },
  getLawyers(state) {
    return state.lawyers
  },
  getLoadingLawyers(state) {
    return state.loadingLawyers
  },
  getErrorLawyers(state) {
    return state.errorLawyers
  },

  // getters for new project
  getIsSendingNewProject(state) {
    return state.isSendingNewProject
  },
  getErrorSendingNewProject(state) {
    return state.errorSendingNewProject
  },
  getIsSendingNewProjectDocuments(state) {
    return state.isSendingNewProjectDocuments
  },
  getErrorSendingNewProjectDocuments(state) {
    return state.errorSendingNewProjectDocuments
  },
  getProjectName(state) {
    return state.projectName
  },

  // getters for entities project
  getEntitiesProjectIdsList(state) {
    return state.entitiesProjectIdsList
  },
  getEntitiesProject(state) {
    return state.entitiesProject
  },
  getLoadingEntitiesProject(state) {
    return state.loadingEntitiesProject
  },
  getErrorEntitiesProject(state) {
    return state.errorEntitiesProject
  },
  getSendingEntity(state) {
    return state.sendingEntity
  },
  getErrorSendingEntity(state) {
    return state.errorSendingEntity
  },
  getNewInfo(state) {
    return state.newInfo
  },
  getCurrentNewInfoId(state) {
    return state.currentNewInfoId
  },
  getSendingNewInfo(state) {
    return state.sendingNewInfo
  },
  getErrorSendingNewInfo(state) {
    return state.errorSendingNewInfo
  },
  getEntitiesData(state) {
    return state.entitiesData
  },
  getLoadingEntitiesData(state) {
    return state.loadingEntitiesData
  },
  getErrorEntitiesData(state) {
    return state.errorEntitiesData
  },
  getRemovingEntityItem(state) {
    return state.removingEntityItem
  },
  getErrorRemovingEntityItem(state) {
    return state.errorRemovingEntityItem
  },
  getPutEntityItem(state) {
    return state.putEntityItem
  },
  getErrorPutEntityItem(state) {
    return state.errorPutEntityItem
  },

  // getters for project
  getProject(state) {
    return state.project
  },
  getLoadingProject(state) {
    return state.loadingProject
  },
  getErrorProject(state) {
    return state.errorProject
  },

  getFilter(state) {
    return state.filter
  },
  getFilterHandlerUpdateStatus(state) {
    return state.filterHandlerUpdateStatus
  },
  getFilterNextTokens(state) {
    return state.filterNextTokens
  },
  getFilterDataCodes(state) {
    return state.filterDataCodes
  },
  getFilterDataSources(state) {
    return state.filterDataSources
  },
  getFilterDataDocumentYears(state) {
    return state.filterDataDocumentYears
  },
  getFilterDataConfidences(state) {
    return state.filterDataConfidences
  },
  getFilterDataEntityName(state) {
    return state.filterDataEntityName
  },
  getFetchingFilters(state) {
    return state.fetchingFilters
  },
  getErrorFetchingFilters(state) {
    return state.errorFetchingFilters
  },

  getRemovingProject(state) {
    return state.removingProject
  },
  getErrorRemovingProject(state) {
    return state.errorRemovingProject
  },

  getPutProject(state) {
    return state.putProject
  },
  getErrorPutProject(state) {
    return state.errorPutProject
  },

  getSearch(state) {
    return state.search
  },

  getPlaceholders(state) {
    return state.placeholders
  },
  getLoadingPlaceholders(state) {
    return state.loadingPlaceholders
  },
  getErrorPlaceholders(state) {
    return state.errorPlaceholders
  },

  getAnalyses(state) {
    return state.analyses
  },
  getLoadingAnalyses(state) {
    return state.loadingAnalyses
  },
  getErrorAnalyses(state) {
    return state.errorAnalyses
  },

  getFetchAllData(state) {
    return state.fetchAllData
  },

  getCloseEntities(state) {
    return state.closeEntities
  },

  getOpenEntities(state) {
    return state.openEntities
  }
}

export default getters

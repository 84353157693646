const state = {
  sendingUser: false,
  errorSendingUser: '',

  usersManagement: [],
  loadingUsersManagement: true,
  errorUsersManagement: '',

  currentUser: null,

  userById: [],
  loadingUserById: true,
  errorUserById: '',

  userDepartments: [],
  loadingUserDepartments: true,
  errorUserDepartments: '',

  userPlaceholders: [],
  loadingUserPlaceholders: true,
  errorUserPlaceholders: '',

  sendingPlaceholderStatus: true,
  errorSendingPlaceholderStatus: '',

  search: '',

  companyData: null
}

export default state

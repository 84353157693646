<template>
  <q-toolbar class="header" :update="update">
    <q-btn
      flat
      @click="handleOpenMenu"
      round
      dense
      icon="menu"
      class="btn-open-menu"
    />
    <span class="back-icon" v-if="isBack" @click="clickBack"></span>
    <div>
      <div class="title" v-if="title && title.length">{{ title }}</div>
      <div v-if="description && description.length">{{ description }}</div>
    </div>

    <q-space />

    <Search v-if="search" />

    <div class="report-mailmerge-status" v-if="showToggleReportStatus & showMailmergeStatus">
        <h3>
          Mise à jour du document ...
        </h3>
          <q-spinner
            v-show="true"
            color='#FA9917'
            class='mailmerge-spinner'
          />
    </div>

    <q-toggle
      v-if="showToggle"
      v-model="projectStatus"
      :label="getProject.projectStatus | translateProjectStatus"
      left-label
      @input="toggleStatus"
    />

    <q-toggle
      v-if="showToggleReportStatus"
      v-model="reportStatus"
      :label="this.getReportStatus | translateReportStatus"
      left-label
      @input="toggleStatusReport"
    />

    <q-btn
      v-if="edit"
      label="Modifier le projet"
      class="btn-edit"
      @click="$router.push(`/edit-project/${$route.params.projectId}`)"
    >
      <q-icon class="edit-icon" />
    </q-btn>

    <div class="d-flex">
      <div class="client-block" v-if="getCurrentUser">
        <div class="info" @click="moveToSettings">
          <div>
            {{ getCurrentUser.firstName }} {{ getCurrentUser.lastName }}
          </div>
          <p>{{ getCurrentUser.primaryDepartment.departmentName }}</p>
        </div>
        <div class="help-box">
          <q-btn
            class="btn-help"
            icon-right="help"
            label="Aide"
            @click="openChat"
          />
        </div>
      </div>

      <span class="logout-icon" @click="logout"></span>
    </div>
  </q-toolbar>
</template>

<script>
import Search from '@/components/global/Search.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Header',
  components: {
    Search
  },
  props: {
    isOpenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prevRoute: null,
      title: '',
      description: '',
      search: false,
      edit: false,
      isBack: false,
      projectStatus: false, // ONGOING || ARCHIVED
      showToggle: false,
      reportStatus: false, // FINISHED || READY
      showToggleReportStatus: false,
      currentRoute: this.$route.name,
      showMailmergeStatus: false
    }
  },
  computed: {
    ...mapGetters('projects', ['getProject']),
    ...mapGetters('documents', ['getReportStatus', 'getNamePDF', 'getMailmergeStatus']),
    ...mapGetters('users', ['getErrorUserById', 'getCurrentUser']),
    update() {
      this.setData()
      this.setStatus()
      return true
    }
  },
  methods: {
    ...mapActions('projects', ['toggleProjectStatus']),
    ...mapActions('documents', ['toggleReportStatus']),
    ...mapActions('users', ['fetchUserById', 'clearCurrentUser']),
    async setUser() {
      if (!this.getCurrentUser) {
        const currentUser = await this.$Amplify.Auth.currentAuthenticatedUser()
        await this.fetchUserById({
          userId: currentUser.username,
          currentUser: true
        })
      }
      this.setCrispData()
    },
    setData() {
      let { data } = this.$route.meta
      let { projectId } = this.$route.params
      this.showToggle = false
      this.showToggleReportStatus = false

      if (projectId && this.getProject && data.toggle) {
        this.title = this.getProject.projectName
        this.description = this.getProject.projectDescription
        this.showToggle = true
      } else {
        this.title = data.title || data.defaultTitle
        this.description = data.description
      }

      if (data.showToggleReportStatus) {
        this.showToggleReportStatus = true
      }

      this.search = data.search
      this.toggle = data.toggle
      this.edit = data.edit
      this.isBack = data.isBack
    },
    setStatus() {
      this.projectStatus =
        this.getProject && this.getProject.projectStatus === 'ONGOING'

      this.reportStatus =
        this.getReportStatus && this.getReportStatus === 'READY'
    },
    handleOpenMenu() {
      this.$emit('toggleOpenMenu', !this.isOpenMenu)
    },
    async logout() {
      const currentUser = await this.$Amplify.Auth.currentAuthenticatedUser({
        bypassCache: true
      })
      await currentUser.signOut()
      this.clearCurrentUser()
      this.$router.push('/login')
    },
    toggleStatus() {
      const status = this.projectStatus ? 'ONGOING' : 'ARCHIVED'
      this.toggleProjectStatus({ projectId: this.getProject.projectId, status })
    },
    async toggleStatusReport() {
      const status = {
        reportStatus: this.reportStatus ? 'READY' : 'FINISHED'
      }
      const { reportId } = this.$route.params
      await this.toggleReportStatus({ reportId, status })
      this.reportStatus =
        this.getReportStatus && this.getReportStatus === 'READY'
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    moveToSettings() {
      if (this.$route.name === 'Settings') return
      this.$router.push('/settings')
    },
    openChat() {
      this.$crisp.push(['do', 'chat:show']);
      this.$crisp.push(['do', 'chat:open']);
    },
    chatClosedCallback() {
      this.$crisp.push(['do', 'chat:hide']);
    },
    async setCrispData() {
      this.$crisp.push(['do', 'chat:hide']);
      this.$crisp.push(["on", "chat:closed", this.chatClosedCallback])
      this.$crisp.push(['set', 'user:company', [process.env.VUE_APP_CLIENT_ID]])
      const current_user = this.getCurrentUser
      if (current_user != undefined) {
        this.$crisp.push(['set', 'user:email', [current_user.email]])
        this.$crisp.push(['set', 'user:nickname', [current_user.firstName + ' ' + current_user.lastName]])
      }
    },
    clickBack() {
      const meta = this.$route.meta
      if (meta.parent === '/templates' || meta.parent === '/documents') {
        this.$router.push(meta.parent)
        return
      }
      if (!this.prevRoute) {
        this.$router.push('/')
        return
      }

      this.$router.go(-1)
    }
  },
  watch: {
    getErrorUserById: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    $route(to, from) {
      this.prevRoute = from
      this.currentRoute = this.$route.name
    },
    getNamePDF: function(name) {
      if (name) {
        this.title = name
      }
    },
    getMailmergeStatus: function(status) {
      if (status == 'ONGOING') {
        this.showMailmergeStatus = true
      } else {
        this.showMailmergeStatus = false
      }
    }
  },
  filters: {
    translateProjectStatus: function(status) {
      if (status == 'ONGOING') {
        return "Statut: En cours"
      } else {
        return "Statut: Terminé"
      }
    },
    translateReportStatus: function(status) {
      if (status == 'READY') {
        return "Statut: En cours"
      } else {
        return "Statut: Terminé"
      }
    }
  },
  mounted() {
    this.setUser()
  }
}
</script>

import { API } from 'aws-amplify'
import { format } from 'date-fns'

const apiName = 'bylawAPI'

const actions = {
  // actions fetch messages
  async fetchMessages({ commit }) {
    commit('setLoading', { status: true })
    commit('setError', { message: '' })
    commit('setMessages', { items: [] })

    try {
      const response = await API.get(apiName, '/message')

      commit('setMessages', { items: response.messages })
    } catch (err) {
      if (err?.response?.status === 404) return
      const { message } = err?.response?.data?.error || ''
      commit('setError', { message })
    } finally {
      commit('setLoading', { status: false })
    }
  },

  // actions fetch messages by project id
  async fetchMessagesByProjectId(
    { commit },
    { projectId, sort = true, update = false }
  ) {
    if (update) {
      commit('setLoadingByProjectId', { status: true })
      commit('setMessagesByProjectId', { items: [] })
    }
    commit('setErrorByProjectId', { message: '' })

    try {
      const response = await API.get(apiName, `/message/${projectId}/last`)

      response.messages = sortByCreatedOn(response.messages)

      if (sort) {
        response.messages = buildMessagesForChat(response.messages)
      }

      commit('setMessagesByProjectId', { items: response.messages })
    } catch (err) {
      if (err?.response?.status === 404) return
      const { message } = err?.response?.data?.error || ''
      commit('setErrorByProjectId', { message })
    } finally {
      commit('setLoadingByProjectId', { status: false })
    }
  },

  // actions send message to project
  async sendMessageToProject({ commit }, data) {
    commit('setIsSendingMessage', { status: true })
    commit('setErrorSendingMessage', { message: '' })

    try {
      await API.post(apiName, `/message/${data.projectId}`, {
        body: { messageContent: data.message }
      })
    } catch (err) {
      if (err?.response?.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingMessage', { message })
    } finally {
      commit('setIsSendingMessage', { status: false })
    }
  },

  // actions for toggle status open/close messages
  toggleIsOpen({ commit }, status) {
    commit('setIsOpen', { status: !status })
  },

  // actions for toggle status open/close messages
  clearMessages({ commit }) {
    commit('setMessagesByProjectId', { items: [] })
    commit('setMessages', { items: [] })
  }
}

// method sort by date
const sortByCreatedOn = array =>
  array.sort((a, b) => {
    if (b.createdOn > a.createdOn) {
      return -1
    }
    if (b.createdOn < a.createdOn) {
      return 1
    }
    return 0
  })

// method build messages for chat
const buildMessagesForChat = array => {
  const newArray = array.reduce((result, item) => {
    let _date = item.createdOn.replace(/\s/, 'T') || new Date()
    const handleFormat = format(new Date(_date), 'dd/MM/yyyy')
    result[handleFormat] = result[handleFormat] || []
    result[handleFormat].push(item)
    return result
  }, {})

  return newArray
}

export default actions

<template>
  <div :class="{ page: true, 'is-open-menu': isOpenMenu }">
    <q-layout container style="height: 100vh">
      <Menu @closeMenu="closeMenu" />
      <Header
        :is-open-menu.sync="isOpenMenu"
        @toggleOpenMenu="toggleOpenMenu"
      />

      <slot />
    </q-layout>
  </div>
</template>

<script>
import Menu from '@/components/global/Menu.vue'
import Header from '@/components/global/Header.vue'

export default {
  name: 'AuthLayout',
  components: {
    Header,
    Menu
  },
  data() {
    return {
      isOpenMenu: false
    }
  },
  methods: {
    closeMenu() {
      this.isOpenMenu = false
    },
    toggleOpenMenu(val) {
      this.isOpenMenu = val
    },
    toggleOpenMessage(val) {
      this.isOpenMessages = val
    },
    clickOutside(e) {
      const el = e.target

      if (el.closest('.btn-open-menu') || el.closest('.menu-list')) {
        e.stopPropagation()
      } else {
        this.isOpenMenu = false
      }
    }
  },
  watch: {
    isOpenMenu() {
      this.isOpenMenu
        ? document.addEventListener('click', this.clickOutside)
        : document.removeEventListener('click', this.clickOutside)
    }
  }
}
</script>

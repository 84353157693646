const getters = {
  getSendingUser(state) {
    return state.sendingUser
  },
  getErrorSendingUser(state) {
    return state.errorSendingUser
  },

  getUsersManagement(state) {
    return state.usersManagement
  },
  getLoadingUsersManagement(state) {
    return state.loadingUsersManagement
  },
  getErrorUsersManagement(state) {
    return state.errorUsersManagement
  },

  getCurrentUser(state) {
    return state.currentUser
  },
  getUserById(state) {
    return state.userById
  },
  getLoadingUserById(state) {
    return state.loadingUserById
  },
  getErrorUserById(state) {
    return state.errorUserById
  },

  getUserDepartments(state) {
    return state.userDepartments
  },
  getLoadingUserDepartments(state) {
    return state.loadingUserDepartments
  },
  getErrorUserDepartments(state) {
    return state.errorUserDepartments
  },

  getUserPlaceholders(state) {
    return state.userPlaceholders
  },
  getLoadingUserPlaceholders(state) {
    return state.loadingUserPlaceholders
  },
  getErrorUserPlaceholders(state) {
    return state.errorUserPlaceholders
  },

  getSendingPlaceholderStatus(state) {
    return state.sendingPlaceholderStatus
  },
  getErrorSendingPlaceholderStatus(state) {
    return state.errorSendingPlaceholderStatus
  },

  getSearch(state) {
    return state.search
  },

  getCompanyData(state) {
    return state.companyData
  }
}

export default getters

const mutations = {
  // mutations for messages
  setMessages(state, { items }) {
    state.messages = items
  },
  setLoading(state, { status }) {
    state.loading = status
  },
  setError(state, { message }) {
    state.error = message
  },

  // mutations for status close/open messages
  setIsOpen(state, { status }) {
    state.isOpen = status
  },

  // mutations for messages by project id
  setMessagesByProjectId(state, { items }) {
    state.messagesByProjectId = items
  },
  setLoadingByProjectId(state, { status }) {
    state.loadingByProjectId = status
  },
  setErrorByProjectId(state, { message }) {
    state.errorByProjectId = message
  },

  // mutations for send message
  setIsSendingMessage(state, { status }) {
    state.isSendingMessage = status
  },
  setErrorSendingMessage(state, { message }) {
    state.errorSendingMessage = message
  }
}

export default mutations

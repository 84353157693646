import Vue from 'vue'

import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';

Vue.use(Quasar, {
  config: {
    alert: {
      color: 'negative',
      colorEmpty: '',
      icon: 'report_problem',
      iconEmpty: '',
      timeout: 5000,
      position: 'top-right',
      messageCode: 'A code has been sent to your mail'
    }
  },
  plugins: {
    Notify
  }
})

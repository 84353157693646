const mutations = {
  setSendingUser(state, { status }) {
    state.sendingUser = status
  },
  setErrorSendingUser(state, { message }) {
    state.errorSendingUser = message
  },

  setUsersManagement(state, { items }) {
    state.usersManagement = items
  },
  setLoadingUsersManagement(state, { status }) {
    state.loadingUsersManagement = status
  },
  setErrorUsersManagement(state, { message }) {
    state.errorUsersManagement = message
  },

  setCurrentUser(state, { item }) {
    state.currentUser = item
  },
  setUserById(state, { item }) {
    state.userById = item
  },
  setLoadingUserById(state, { status }) {
    state.loadingUserById = status
  },
  setErrorUserById(state, { message }) {
    state.errorUserById = message
  },

  setUserDepartments(state, { items }) {
    state.userDepartments = items
  },
  setLoadingUserDepartments(state, { status }) {
    state.loadingUserDepartments = status
  },
  setErrorUserDepartments(state, { message }) {
    state.errorUserDepartments = message
  },

  setUserPlaceholders(state, { items }) {
    state.userPlaceholders = items
  },
  setLoadingUserPlaceholders(state, { status }) {
    state.loadingUserPlaceholders = status
  },
  setErrorUserPlaceholders(state, { message }) {
    state.errorUserPlaceholders = message
  },

  setSendingPlaceholderStatus(state, { status }) {
    state.sendingPlaceholderStatus = status
  },
  setErrorSendingPlaceholderStatus(state, { message }) {
    state.errorSendingPlaceholderStatus = message
  },

  setSearch(state, { message }) {
    state.search = message
  },

  setCompanyData(state, { item }) {
    state.companyData = item
  }
}

export default mutations

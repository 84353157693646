import Vue from 'vue'
import Vuex from 'vuex'

import users from './modules/users'
import messages from './modules/messages'
import documents from './modules/documents'
import projects from './modules/projects'
import templates from './modules/templates'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    users,
    messages,
    documents,
    projects,
    templates
  },
  state: {
    test: []
  },
  mutations: {},
  actions: {}
})

export default store

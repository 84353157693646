const getters = {
  getListPlaceholders(state) {
    return state.listPlaceholders
  },
  getEntityList(state) {
    return state.entityList
  },
  getEdit(state) {
    return state.stateEdit
  },
  getSingleEntity(state) {
    return state.singleEntity
  },
  getSearch(state) {
    return state.search
  },
  getDocumentEntities(state) {
    return state.documentEntities
  },
  getDocumentType(state) {
    return state.documentType
  },
  newReportData(state) {
    return state.newReportData
  },
  getNewEntityFields(state) {
    return state.newEntityFields
  },
  getShowNewFields(state) {
    return state.showNewFields
  },
  getReportName(state) {
    return state.reportName
  },
  getReportDataResponse(state) {
    return state.reportDataResponse
  },
  getIndexEditingEntity(state) {
    return state.indexEditingEntity
  },
  getEntityId(state) {
    return state.entityId
  },
  getOldLabel(state) {
    return state.oldLabel
  },
  getEntityListDublicate(state) {
    return state.entityListDublicate
  },
  getS3url(state) {
    return state.s3Url
  },
  getPlacedStatus(state) {
    return state.placedStatus
  },
  getDocFocused(state) {
    return state.docFocused
  },
  getEntityStatus(state) {
    return state.entityStatus
  },
  getErrorEntityName(state) {
    return state.errorEntityName
  },
  getShowUnsavedChanges(state) {
    return state.showUnsavedChanges
  }
}

export default getters

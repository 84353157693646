const getters = {
  // getters for messages
  getMessages(state) {
    return state.messages
  },
  getLoading(state) {
    return state.loading
  },
  getError(state) {
    return state.error
  },

  // getters status open/close messages
  getIsOpen(state) {
    return state.isOpen
  },

  // getters for messages by project id
  getMessagesByProjectId(state) {
    return state.messagesByProjectId
  },
  getLoadingByProjectId(state) {
    return state.loadingByProjectId
  },
  getErrorByProjectId(state) {
    return state.errorByProjectId
  },

  // getters status sending message
  getIsSendingMessage(state) {
    return state.isSendingMessage
  },
  getErrorSendingMessage(state) {
    return state.errorSendingMessage
  }
}

export default getters

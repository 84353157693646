import Vue from 'vue'
import App from './App.vue'
import * as AmplifyModules from 'aws-amplify'
import { Amplify } from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsconfig from './awsconfig'
import IdleVue from 'idle-vue'
import VueTimers from 'vue-timers'
import { Plugin } from 'vue-fragment'
import router from './router'
import store from './store'
import './quasar'
import Filters from './filters'
import CrispChat from '@dansmaculotte/vue-crisp-chat'

import './styles/app.scss'
import Default from '@/layouts/Default'
import Auth from '@/layouts/Auth'

Amplify.configure(awsconfig)
Vue.use(AmplifyPlugin, AmplifyModules)

Vue.config.productionTip = true

Vue.component('default-layout', Default)
Vue.component('auth-layout', Auth)

Vue.use(Plugin)
Vue.use(Filters)
Vue.use(VueTimers)

const eventsHub = new Vue()
const oneHour = 3600 * 1000

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: oneHour
})

Vue.use(CrispChat, {
  websiteId: '9c07c9d5-e694-49e0-af50-e164e3243720',
  disabled: false,
  hideOnLoad: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

var window_width = window.innerWidth

var is_open
if (window_width < 1536) {
  is_open = false
} else {
  is_open = true
}

const state = {
  messages: [],
  loading: true,
  error: '',
  isOpen: is_open,
  messagesByProjectId: {},
  loadingByProjectId: true,
  errorByProjectId: '',
  isSendingMessage: false,
  errorSendingMessage: false
}

export default state

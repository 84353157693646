import { API } from 'aws-amplify'
import { getTime } from 'date-fns'
const apiName = 'bylawAPI'

const actions = {
  setBlockPage({ commit }, status) {
    commit('setBlockPage', status)
  },
  // action add entity to project
  async addEntity({ commit }, { projectId, entityName, entityType }) {
    try {
      const response = await API.post(apiName, `/analyse/entity/${projectId}`, {
        body: {
          entityName,
          entityType: entityType.toUpperCase(),
          forceCreate: false,
          entityAlternativeNames: []
        }
      })
      return response
    } catch (err) {
      const error = err.response
      return Promise.reject(error)
    }
  },
  // actions last documents
  async fetchLastDocuments({ commit }) {
    commit('setLoadingLastDocuments', { status: true })
    commit('setErrorLastDocuments', { message: '' })
    commit('setLastDocuments', { items: [] })

    try {
      const response = await API.get(apiName, '/report/last')

      response.reports = response.reports.sort((a, b) => {
        const xFormat = getTime(new Date(a.updatedOn))
        const yFormat = getTime(new Date(b.updatedOn))

        return xFormat > yFormat ? -1 : xFormat < yFormat ? 1 : 0
      })

      commit('setLastDocuments', { items: response.reports })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorLastDocuments', { message })
    } finally {
      commit('setLoadingLastDocuments', { status: false })
    }
  },

  // actions all documents
  async fetchDocuments({ commit }) {
    commit('setLoadingDocuments', { status: true })
    commit('setErrorDocuments', { message: '' })
    commit('setDocuments', { items: [] })

    try {
      const response = await API.get(apiName, '/report')

      commit('setDocuments', { items: response.reports })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorDocuments', { message })
    } finally {
      commit('setLoadingDocuments', { status: false })
    }
  },

  // actions put document

  async putDocument({ commit }, { documentId, data }) {
    commit('setErrorDocument', { message: '' })
    try {
      const response = await API.put(apiName, `/document/${documentId}`, {
        body: { ...data }
      })
    } catch (err) {
      if (err.response?.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorDocument', { message })
    }
  },
  // actions document
  async fetchDocument({ commit }, { documentId }) {
    commit('setLoadingDocument', { status: true })
    commit('setErrorDocument', { message: '' })
    // commit('setDocument', { item: [] })
    // commit('setFile', { item: [] })

    try {
      const response = await API.get(apiName, `/document/${documentId}`)
      // const result = await fetch(response.s3Url).then(res => res.blob())

      commit('setDocument', response)

      // commit('setFile', {
      //   item: [{ result, name: response.documentFileName }]
      // })
    } catch (err) {
      if (err.response?.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorDocument', { message })
    } finally {
      commit('setLoadingDocument', { status: false })
    }
  },

  async fetchAnalyseEntity({ commit }) {
    commit('setErrorAnalyseEntity', { message: '' })

    try {
      const response = await API.get(apiName, '/analyse/entity')
      commit('setAnalyseEntity', response.entities)
    } catch (err) {
      if (err.response.status === 404) return
      const message = err?.response?.data?.error?.message || 'Une erreur est survenue'
      commit('setErrorAnalyseEntity', { message })
    }
  },

  // actions analyse document
  async putAnalyseDocument({ commit }, data) {
    commit('setPutEntityItem', { status: true })
    commit('setErrorPutEntityItem', { message: '' })

    try {
      await API.put(apiName, '/analyse', { body: { ...data } })
    } catch (err) {
      if (err.response.status === 404) return
      const message = err?.response?.data?.error?.message || 'Une erreur est survenue'
      commit('setErrorPutEntityItem', { message })
    } finally {
      commit('setPutEntityItem', { status: false })
    }
  },

  async deleteAnalyse({ commit }, data) {
    try {
      await API.del(apiName, '/analyse', { body: { ...data } })
    } catch (err) {
      console.error(err)
    }
  },

  // actions models
  async fetchModels({ commit }) {
    commit('setLoadingModels', { status: true })
    commit('setErrorModels', { message: '' })
    commit('setModels', { items: [] })

    try {
      const response = await API.get(apiName, '/model/all')

      response.models = response.models
        .filter(
          item =>
            item.modelStatus === 'READY' || item.modelStatus === 'FIRST_UPLOAD'
        )

      commit('setModels', { items: response.models })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorModels', { message })
    } finally {
      commit('setLoadingModels', { status: false })
    }
  },

  // actions models last
  async fetchModelsLast({ commit }) {
    commit('setLoadingModelsLast', { status: true })
    commit('setErrorModelsLast', { message: '' })
    commit('setModelsLast', { items: [] })

    try {
      const response = await API.get(apiName, '/model/last')

      response.models = response.models
        .filter(
          item =>
            item.modelStatus === 'READY' || item.modelStatus === 'FIRST_UPLOAD'
        )
        .sort((a, b) => {
          const x = getTime(new Date(a.updatedOn))
          const y = getTime(new Date(b.updatedOn))

          return x > y ? -1 : x < y ? 1 : 0
        })

      commit('setModelsLast', { items: response.models })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorModelsLast', { message })
    } finally {
      commit('setLoadingModelsLast', { status: false })
    }
  },

  // actions sending document
  async sendDocument({ commit }, data) {
    commit('setSendingDocument', { status: true })
    commit('setErrorSendingDocument', { message: '' })

    try {
      return await API.post(apiName, '/report', {
        body: { ...data }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingDocument', { message })
    } finally {
      commit('setSendingDocument', { status: false })
    }
  },

  // actions documents by project id
  async fetchDocumentsByProjectId({ commit }, { projectId }) {
    commit('setLoadingDocumentsByProjectId', { status: true })
    commit('setErrorDocumentsByProjectId', { message: '' })
    commit('setDocumentsByProjectId', { items: [] })

    try {
      const response = await API.get(apiName, `/report/project/${projectId}`)

      commit('setDocumentsByProjectId', { items: response.reports })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorDocumentsByProjectId', { message })
    } finally {
      commit('setLoadingDocumentsByProjectId', { status: false })
    }
  },

  // actions materials by project id
  async fetchMaterialsByProjectId({ commit }, { projectId }) {
    commit('setLoadingMaterialsByProjectId', { status: true })
    commit('setErrorMaterialsByProjectId', { message: '' })
    commit('setMaterialsByProjectId', { items: [] })

    try {
      const response = await API.get(apiName, `/document/all/${projectId}`)

      response.entities = response.entities.map(entity => {
        entity.entityDocuments = entity.entityDocuments.sort((a, b) => {
          const xFormat = getTime(new Date(a.updatedOn))
          const yFormat = getTime(new Date(b.updatedOn))

          return xFormat > yFormat ? -1 : xFormat < yFormat ? 1 : 0
        })

        return entity
      })

      commit('setMaterialsByProjectId', { items: response.entities })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorMaterialsByProjectId', { message })
    } finally {
      commit('setLoadingMaterialsByProjectId', { status: false })
    }
  },

  // actions remove material
  async removeMaterial({ commit }, { documentId }) {
    commit('setRemovingMaterial', { status: true })
    commit('setErrorRemovingMaterial', { message: '' })

    try {
      await API.del(apiName, `/document/${documentId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorRemovingMaterial', { message })
    } finally {
      commit('setRemovingMaterial', { status: false })
    }
  },

  // actions send model
  async sendModel({ commit }, { data }) {
    commit('setSendingModel', { status: true })
    commit('setErrorSendingModel', { message: '' })

    try {
      return await API.post(apiName, `/model/url/upload`, {
        body: { ...data }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingModel', { message })
    } finally {
      commit('setSendingModel', { status: false })
    }
  },

  // actions duplicate model
  async duplicateModel({ commit }, data ) {
    commit('setSendingModel', { status: true })
    commit('setErrorSendingModel', { message: '' })

    try {
      return await API.post(apiName, `/model/duplicate`, {
        body: { ...data }
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingModel', { message })
    } finally {
      commit('setSendingModel', { status: false })
    }
  },

  // actions put model upload
  async putModelUpload({ commit }, { modelId }) {
    commit('setSendingModel', { status: true })
    commit('setErrorSendingModel', { message: '' })

    try {
      return await API.put(apiName, `/model/url/upload/${modelId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingModel', { message })
    } finally {
      commit('setSendingModel', { status: false })
    }
  },

  // actions put model
  async putModel({ commit }, { modelId, data }) {
    commit('setSendingModel', { status: true })
    commit('setErrorSendingModel', { message: '' })

    try {
      await API.put(apiName, `/model/${modelId}`, { body: { ...data } })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingModel', { message })
    } finally {
      commit('setSendingModel', { status: false })
    }
  },

  async fetchMailMerge({ commit }, report_id) {
    commit('setMailmergeStatus', 'ONGOING')

    try {
      const response = await API.post(apiName, `/report/mailmerge/${report_id}`)
      return response
    } catch (err) {
      if (err) return err
    }
  },

  setS3Url({ commit }, val) {
    commit('setS3Url', val)
  },

  async uploadModelToS3({ commit }, { s3UrlObject, file, type, doc }) {
    commit('setErrorSendingModel', { message: '' })
    const { s3Url, s3UrlSfdt } = s3UrlObject
    const url = s3Url || s3UrlSfdt

    try {
      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: { 'content-type': type }
      })

      if (doc) {
        const { s3UrlSource } = s3UrlObject

        await fetch(s3UrlSource, {
          method: 'PUT',
          body: doc,
          headers: { 'content-type': type }
        })
      }
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingModel', { message })
    }
  },

  async fetchModel({ commit }, { modelId }) {
    commit('setLoadingModel', { status: true })
    commit('setErrorModel', { message: '' })

    try {
      return await API.get(apiName, `/model/${modelId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorModel', { message })
    }
  },

  fetchModelDone({ commit }) {
    commit('setLoadingModel', { status: false })
  },

  setIsActiveCreateDocument({ commit }, { status }) {
    commit('setIsActiveCreateDocument', { status })
  },

  async fetchReviewDocument({ commit }, { document }) {
    commit('setLoadingReviewDocument', { status: true })
    commit('setErrorReviewDocument', { message: '' })
    commit('setReviewDocument', { item: null })
    commit('setDocument', { item: [] })
    // debugger
    try {
      if (!document.sourceDocumentId) {
        commit('setLoadingReviewDocument', { status: false })
        return
      }
      const response = await API.get(
        apiName,
        `/document/${document.sourceDocumentId}`
      )
      const result = await fetch(response.s3Url).then(res => res.blob())
      const data = {
        fileName: response.documentFileName,
        sourceDocumentDate: document.sourceDocumentDate,
        sourceDocumentType: document.sourceDocumentType,
        dataValue: document.dataValue,
        dataId: document.dataId
      }

      commit('setReviewDocument', {
        item: [{ result, name: response.documentFileName, data }]
      })
      commit('setDocument', response)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorReviewDocument', { message })
    } finally {
      commit('setLoadingReviewDocument', { status: false })
    }
  },

  async downloadByReportId({ commit }, { reportId }) {
    commit('setDownload', { status: true })
    commit('setErrorDownload', { message: '' })
    try {
      return await API.post(apiName, `/report/mailmerge/${reportId}`)
    } catch (err) {
      const message = err?.response?.data?.error?.message || 'Une erreur est survenue'
      commit('setErrorDownload', { message })
    } finally {
      commit('setDownload', { status: false })
    }
  },

  // actions remove model
  async removeModel({ commit }, { modelId }) {
    commit('setRemovingModel', { status: true })
    commit('setDeleteStatus', { status: true })
    commit('setErrorRemovingModel', { message: '' })

    try {
      await API.del(apiName, `/model/${modelId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorRemovingModel', { message })
    } finally {
      commit('setRemovingModel', { status: false })
    }
  },

  async fetchReport({ commit }, { reportId }) {
    commit('setLoadingReport', { status: true })
    commit('setErrorReport', { message: '' })

    try {
      return await API.get(apiName, `/report/${reportId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorReport', { message })
    }
  },

  fetchReportDone({ commit }) {
    commit('setLoadingReport', { status: false })
  },

  // actions remove report
  async removeReport({ commit }, { reportId }) {
    commit('setRemovingReport', { status: true })
    commit('setDeleteStatus', { status: true })
    commit('setErrorRemovingReport', { message: '' })

    try {
      await API.del(apiName, `/report/${reportId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err?.response?.data?.error || 'Une erreur est survenue'
      commit('setErrorRemovingReport', { message })
    } finally {
      commit('setRemovingReport', { status: false })
    }
  },

  async putReportUpload({ commit }, { reportId }) {
    commit('setSendingReport', { status: true })
    commit('setErrorSendingReport', { message: '' })

    try {
      return await API.put(apiName, `/report/url/upload/${reportId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingReport', { message })
    } finally {
      commit('setSendingReport', { status: false })
    }
  },

  async putReport({ commit }, { reportId, data }) {
    commit('setSendingReport', { status: true })
    commit('setErrorSendingReport', { message: '' })

    try {
      await API.put(apiName, `/report/${reportId}`, { body: { ...data } })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      commit('setErrorSendingReport', { message })
    } finally {
      commit('setSendingReport', { status: false })
    }
  },

  setReportStatus({ commit }, { status }) {
    commit('setReportStatus', { status })
  },

  async toggleReportStatus({ commit }, { reportId, status }) {
    commit('setLoadingReportStatus', { status: true })
    commit('setErrorReportStatus', { message: '' })

    try {
      await API.put(apiName, `/report/status/${reportId}`, {
        body: { ...status }
      })

      commit('setReportStatus', {
        status: status.reportStatus !== 'READY' ? 'FINISHED' : 'READY'
      })
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      const status = status.reportStatus === 'READY' ? 'FINISHED' : 'READY'
      commit('setReportStatus', { status })
      commit('setErrorReportStatus', { message })
    } finally {
      commit('setLoadingReportStatus', { status: false })
    }
  },

  setSearchDocuments({ commit }, { message }) {
    commit('setSearch', { message })
  },

  setSearchEntity({ commit }, { message }) {
    commit('setSearch', { message })
  },

  setNamePDF({ commit }, { name }) {
    commit('setNamePDF', { name })
  },

  setMailmergeStatus({ commit }, { status }) {
    commit('setMailmergeStatus', { status })
  },

  async fetchProgressDocuments(_, { projectId }) {
    try {
      const response = await API.get(apiName, `/document/status/${projectId}`)
      return response.status
    } catch (err) {
      if (err.response.status === 404) return false
      return err?.response?.data?.error?.message || 'Une erreur est survenue'
    }
  },

  async fetchReportId({ commit }, reportId) {
    try {
      const response = await API.get(apiName, `/report/${reportId}`)
      commit('setFetchEntityList', response)
      return response
    } catch (err) {
      if (err) return err
    }
  },

  setDeleteStatus({ commit }, { status }) {
    commit('setDeleteStatus', { status })
  }
}

export default actions

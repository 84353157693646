import { API } from 'aws-amplify'

const apiName = 'bylawAPI'

const actions = {
  setShowUnsavedChanges({ commit }, { status, payload }) {
    commit('setShowUnsavedChanges', { status, payload })
  },
  setErrorEntityName({ commit }, status) {
    commit('setErrorEntityName', status)
  },
  setEntityStatus({ commit }, status) {
    commit('setEntityStatus', status)
  },
  clearStateEdit({ commit }) {
    commit('clearStateEdit')
  },
  setSingleEntityPlaceholder({ commit }, name) {
    commit('setSingleEntityPlaceholder', name)
  },
  setEntityOldList({ commit }, list) {
    const readOnlyArr = list
    for (const prop of readOnlyArr) {
      prop.status = 'read-only'
    }
    commit('setOldEntityList', readOnlyArr)
  },
  setEntityListDublicate({ commit }, list) {
    commit('setEntityDublicate', list)
  },
  setOldLabel({ commit }, label) {
    commit('setOldLabel', label)
  },
  editEntity({ commit }, index) {
    commit('editEntity', index)
  },
  saveEntity({ commit }, project_state) {
    commit('saveEntity', project_state)
  },
  setEditableStateForPlaceholder(
    { commit },
    { indexEntity, indexPlaceholder, flag }
  ) {
    commit('setEditableStateForPlaceholder', {
      indexEntity,
      indexPlaceholder,
      flag
    })
  },
  addNewEntity({ getters, commit }, type) {
    commit('setEntityDublicate', getters.getEntityList)
    const entity = {}

    var iter_entity_number = 1
    var FOUND_AVAILABLE_ENTITY = false
    while (FOUND_AVAILABLE_ENTITY == false) {
      FOUND_AVAILABLE_ENTITY = true
      var entity_code = `ENTITY_${iter_entity_number}`
      var entity_name = `Entity_${iter_entity_number}`
      for (const entity_elem of getters.getEntityList) {
        if (entity_code == entity_elem.code | entity_name == entity_elem.name) {
          iter_entity_number += 1
          FOUND_AVAILABLE_ENTITY = false
        }
      }
    }

    entity.name = entity_name
    entity.code = entity_code
    entity.id = getters.getEntityList.length.toString()
    entity.placeholder = null
    entity.search = null
    entity.type = type
    entity.status = 'edit'
    entity.searchOfCategories = false
    entity.fields = []
    commit('setSingleEntity', entity)
    commit('setEntityList', entity)
  },
  async fetchListPlaceholders({ commit }) {
    try {
      const response = await API.get(apiName, '/analyse/placeholder/template')
      commit('setListPlaceholders', { items: response.placeholders })
      return response
    } catch (err) {
      if (err.response?.status === 404) return err
    }
  },
  async fetchListPlaceholdersByEntityId({ commit }, entityId) {
    try {
      const response = await API.get(
        apiName,
        `/analyse/placeholder/${entityId}`
      )
      commit('setListPlaceholders', { items: response.placeholders })
      return response
    } catch (err) {
      if (err.response?.status === 404) return err
    }
  },
  async fetchListEntity({ commit }, model_id) {
    try {
      const response = await API.get(apiName, `/model/${model_id}`)
      commit('setFetchEntityList', response)
      return response
    } catch (err) {
      if (err) return err
    }
  },
  async fetchProjectId({}, report_id) {
    try {
      const response = await API.get(apiName, `/report/${report_id}`)
      return response
    } catch (err) {
      if (err) return err
    }
  },
  async fetchReportData(
    { state, getters },
    { reportId, entityCode, entityId }
  ) {
    try {
      const response = await API.get(
        apiName,
        `/report/data/${reportId}/${entityCode}/${entityId}`
      )
      state.entityList[getters.getIndexEditingEntity].fields =
        response.reportEntityData
      return response
    } catch (err) {
      if (err) return err
    }
  },
  async putReportUpload({}, { reportId }) {
    try {
      return await API.put(apiName, `/report/url/upload/${reportId}`)
    } catch (err) {
      if (err.response.status === 404) return
      const { message } = err.response.data.error
      // commit('setErrorSendingReport', { message })
    } finally {
      // commit('setSendingReport', { status: false })
    }
  },
  async fetchMailMerge({}, report_id) {
    try {
      const response = await API.post(apiName, `/report/mailmerge/${report_id}`)
      return response
    } catch (err) {
      if (err) return err
    }
  },
  async fetchReplaceEntities({ commit }, project_id) {
    try {
      const response = await API.get(apiName, `/analyse/entity/${project_id}`)
      commit('setDocumentEntities', response)
    } catch (err) {
      if (err) return err
    }
  },
  async fetchFilterDataEntity({ commit, getters }, [entity_id]) {
    const newArr = []
    for (const prop of getters.getEntityList[getters.getIndexEditingEntity]
      .entityData ||
      getters.getEntityList[getters.getIndexEditingEntity].fields) {
      newArr.push(prop.dataCode || prop.code)
    }
    const filters = {
      nextToken: {},
      filterDataCodes: newArr,
      filterDataSources: [],
      filterDataDocumentYears: [],
      filterDataConfidences: []
    }
    const response = await API.post(
      apiName,
      `/analyse/entity/data/${entity_id}`,
      { body: filters }
    )
    commit('setNewEntityFields', response.data)
  },
  async fetchUpgradeEntity({}, [report_id, obj]) {
    const response = await API.put(apiName, `/report/${report_id}`, {
      body: obj
    })
    return response
  },
  setReportName({ commit }, reportName) {
    commit('setReportName', reportName)
  },
  setEditableDataForPlaceholder(
    { commit },
    [index_entity, index_placeholder, val]
  ) {
    commit('setEditableDataForPlaceholder', [
      index_entity,
      index_placeholder,
      val
    ])
  },
  deleteEntity({ commit }) {
    commit('deleteEntity')
  },
  removeSelected({ commit }, index) {
    commit('removeSelected', index)
  },
  setFetchEntityList({ commit }, val) {
    commit('setFetchEntityList', val)
  },
  setSingleEntityName({ commit }, name) {
    commit('setSingleEntityName', name)
  },
  setReportDataResponse({ commit }, val) {
    commit('setReportDataResponse', JSON.parse(JSON.stringify(val)))
  },
  setEntityList({ commit }, arr) {
    commit('setEntityListNewArray', arr)
  },
  setSearchTemplates({ commit }, { message }) {
    commit('setSearch', message)
  },
  setS3Url({ commit }, val) {
    commit('setS3Url', val)
  },
  setEntityId({ commit }, id) {
    commit('setEntityId', id)
  },
  setReportData({ commit }, list) {
    const single = JSON.parse(JSON.stringify(list))
    const entity = {}
    entity.entityCode = single.name.toUpperCase()
    entity.entityLabel = single.name
    entity.entityValue = single.name.toUpperCase()
    entity.entityId = single.id
    entity.data = []
    for (const prop of single.fields) {
      const newObj = {}
      newObj.dataCode = prop.code
      newObj.dataLabel = prop.text
      newObj.dataValue = prop.value
      entity.data.push(newObj)
    }

    commit('setReportData', entity)
  },
  clearOldFields({ commit }) {
    commit('clearOldFields')
  },
  clearSingleEntityFields({ commit }) {
    commit('clearSingleEntityFields')
  },
  clearSingleEntity({ commit }) {
    commit('clearSingleEntity')
  },
  setShowNewFields({ commit }, val) {
    commit('setShowNewFields', val)
  },
  setIdInEntityItem({ commit }, [index, id]) {
    commit('setIdInEntityItem', [index, id])
  },
  setPlacedStatus({ commit }, status) {
    commit('setPlacedStatus', status)
  },
  setPlacedStatusForField({ commit }, [indexItem, indexField, status]) {
    commit('setPlacedStatusInField', [indexItem, indexField, status])
  },
  setFocusedDocument({ commit }, status) {
    commit('setDocFocused', status)
  },
  setFieldsEntity({ state }, [arr, documentArgument = false]) {
    if (!documentArgument) {
      for (const prop of state.entityList) {
        if (prop.status === 'edit') {
          const arrNew = []
          if (prop.fields.length > arr.length) {
            for (let str of prop.fields) {
              if (arr.some(i => i.code === str.code)) {
                arrNew.push(str)
              }
            }
            prop.fields = arrNew
            state.singleEntity.fields = arrNew
          } else {
            for (let str of arr) {
              const isFind = prop.fields.some(i => {
                return i.value === str.value
              })
              if (!isFind) {
                prop.fields.push(str)
                state.singleEntity.fields = prop.fields
              }
            }
          }
          return
        }
      }
    } else {
      const findEdit = state.entityList.find(entity => entity.status === 'edit')
      findEdit.fields = arr
      state.singleEntity.fields = arr
      return
    }
  }
}

export default actions

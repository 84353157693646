import { Auth } from 'aws-amplify'

export default {
  Auth: {
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_REGION,
    identityPoolRegion: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID
  },
  AWSS3: {
    bucket: '', //REQUIRED -  Amazon S3 bucket name
    region: process.env.VUE_APP_REGION //OPTIONAL -  Amazon service region
  },
  API: {
    endpoints: [
      {
        name: 'bylawAPI',
        endpoint: process.env.VUE_APP_API_URL,
        region: process.env.VUE_APP_REGION,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      }
    ]
  }
}

const getters = {
  // getters last documents
  getLastDocuments(state) {
    return state.lastDocuments
  },
  getLoadingLastDocuments(state) {
    return state.loadingLastDocuments
  },
  getErrorLastDocuments(state) {
    return state.errorLastsDocuments
  },

  // getters all documents
  getDocuments(state) {
    return state.documents
  },
  getLoadingDocuments(state) {
    return state.loadingDocuments
  },
  getErrorDocuments(state) {
    return state.errorDocuments
  },

  // getters document
  getFile(state) {
    return state.file
  },
  getDocument(state) {
    return state.document
  },
  getLoadingDocument(state) {
    return state.loadingDocument
  },
  getErrorDocument(state) {
    return state.errorDocument
  },

  // getters models
  getModels(state) {
    return state.models
  },
  getLoadingModels(state) {
    return state.loadingModels
  },
  getErrorModels(state) {
    return state.errorModels
  },

  // getters models last
  getModelsLast(state) {
    return state.modelsLast
  },
  getLoadingModelsLast(state) {
    return state.loadingModelsLast
  },
  getErrorModelsLast(state) {
    return state.errorModelsLast
  },

  getSendingDocument(state) {
    return state.sendingDocument
  },
  getErrorSendingDocument(state) {
    return state.errorSendingDocument
  },

  // getters documents by project id
  getDocumentsByProjectId(state) {
    return state.documentsByProjectId
  },
  getLoadingDocumentsByProjectId(state) {
    return state.loadingDocumentsByProjectId
  },
  getErrorDocumentsByProjectId(state) {
    return state.errorDocumentsByProjectId
  },

  // getters materials by project id
  getMaterialsByProjectId(state) {
    return state.materialsByProjectId
  },
  getLoadingMaterialsByProjectId(state) {
    return state.loadingMaterialsByProjectId
  },
  getErrorMaterialsByProjectId(state) {
    return state.errorMaterialsByProjectId
  },

  getRemovingMaterial(state) {
    return state.removingMaterial
  },
  getErrorRemovingMaterial(state) {
    return state.errorRemovingMaterial
  },

  getSendingModel(state) {
    return state.sendingModel
  },
  getErrorSendingModel(state) {
    return state.errorSendingModel
  },

  // getters simple model
  getModel(state) {
    return state.model
  },
  getLoadingModel(state) {
    return state.loadingModel
  },
  getErrorModel(state) {
    return state.errorModel
  },

  getIsActiveCreateDocument(state) {
    return state.isActiveCreateDocument
  },

  getReviewDocument(state) {
    return state.reviewDocument
  },
  getLoadingReviewDocument(state) {
    return state.loadingReviewDocument
  },
  getErrorReviewDocument(state) {
    return state.errorReviewDocument
  },

  getDownload(state) {
    return state.download
  },
  getErrorDownload(state) {
    return state.errorDownload
  },

  getRemovingModel(state) {
    return state.removingModel
  },
  getErrorRemovingModel(state) {
    return state.errorRemovingModel
  },

  getReport(state) {
    return state.report
  },
  getLoadingReport(state) {
    return state.loadingReport
  },
  getErrorReport(state) {
    return state.errorReport
  },

  getRemovingReport(state) {
    return state.removingReport
  },
  getErrorRemovingReport(state) {
    return state.errorRemovingReport
  },

  getSendingReport(state) {
    return state.sendingReport
  },
  getErrorSendingReport(state) {
    return state.errorSendingReport
  },

  getReportStatus(state) {
    return state.reportStatus
  },
  getLoadingReportStatus(state) {
    return state.loadingReportStatus
  },
  getErrorReportStatus(state) {
    return state.errorReportStatus
  },

  getSearch(state) {
    return state.search
  },

  getNamePDF(state) {
    return state.namePDF
  },
  getAnalyseEntity (state) {
    return state.analyseEntityList
  },
  getErrorAnalyseEntityList(state) {
    return state.errorAnalyseEntityList
  },
  getS3urlMailmerge(state) {
    return state.s3Url
  },

  getMailmergeStatus(state) {
    return state.mailmergeStatus
  },

  getDeleteStatus(state) {
    return state.deleteStatus
  }
}

export default getters

import { defaultNewInfo } from '@/store/modules/projects/state'
import Vue from 'vue'

const mutations = {
  // mutations for all projects
  setAllProjects(state, { items }) {
    state.allProjects = items
  },
  setLoadingAllProjects(state, { status }) {
    state.loadingAllProjects = status
  },
  setErrorAllProjects(state, { message }) {
    state.errorAllProjects = message
  },

  // mutations for last projects
  setLastProjects(state, { items }) {
    state.lastProjects = items
  },
  setLoadingLastProjects(state, { status }) {
    state.loadingLastProjects = status
  },
  setErrorLastProjects(state, { message }) {
    state.errorLastsProjects = message
  },

  // mutations for options new project
  setDepartments(state, { items }) {
    state.departments = items
  },
  setLoadingDepartments(state, { status }) {
    state.loadingDepartments = status
  },
  setErrorDepartments(state, { message }) {
    state.errorDepartments = message
  },
  setLawyers(state, { items }) {
    state.lawyers = items
  },
  setLoadingLawyers(state, { status }) {
    state.loadingLawyers = status
  },
  setErrorLawyers(state, { message }) {
    state.errorLawyers = message
  },

  // mutations for new project
  setIsSendingNewProject(state, { status }) {
    state.isSendingNewProject = status
  },
  setErrorSendingNewProject(state, { message }) {
    state.errorSendingNewProject = message
  },
  setIsSendingNewProjectDocuments(state, { status }) {
    state.isSendingNewProjectDocuments = status
  },
  setErrorSendingNewProjectDocuments(state, { message }) {
    state.errorSendingNewProjectDocuments = message
  },
  setCreateProjectName(state, { projectName }) {
    state.projectName = projectName
  },

  // mutations for entities project
  setEntitiesProjectIdsList(state, { ids }) {
    state.entitiesProjectIdsList = ids
  },
  setEntitiesProject(state, { items }) {
    state.entitiesProject = items
  },
  setLoadingEntitiesProject(state, { status }) {
    state.loadingEntitiesProject = status
  },
  setErrorEntitiesProject(state, { message }) {
    state.errorEntitiesProject = message
  },
  setSendingEntity(state, { status }) {
    state.sendingEntity = status
  },
  setErrorSendingEntity(state, { message }) {
    state.errorSendingEntity = message
  },
  setCurrentNewInfoId(state, { id }) {
    state.currentNewInfoId = id
  },
  clearNewInfo(state) {
    state.newInfo = { ...defaultNewInfo }
  },
  setSendingNewInfo(state, { status }) {
    state.sendingNewInfo = status
  },
  setErrorSendingNewInfo(state, { message }) {
    state.errorSendingNewInfo = message
  },
  setEntitiesData (state, { items }) {
    Vue.set(state.entitiesData, items.entityId, items.data)
    state.entitiesData.length = state.entitiesData.length + 1
  },
  setLoadingEntitiesData(state, { status }) {
    state.loadingEntitiesData = status
  },
  setErrorEntitiesData(state, { message }) {
    state.errorEntitiesData = message
  },
  setRemovingEntityItem(state, { status }) {
    state.removingEntityItem = status
  },
  setErrorRemovingEntityItem(state, { message }) {
    state.errorRemovingEntityItem = message
  },
  setPutEntityItem(state, { status }) {
    state.putEntityItem = status
  },
  setErrorPutEntityItem(state, { message }) {
    state.errorPutEntityItem = message
  },

  // mutations project
  setProject(state, { item }) {
    state.project = item
  },
  setLoadingProject(state, { status }) {
    state.loadingProject = status
  },
  setErrorProject(state, { message }) {
    state.errorProject = message
  },

  // mutations project status
  setProjectStatus(state, { status }) {
    state.project.projectStatus = status
  },
  setLoadingProjectStatus(state, { status }) {
    state.loadingProjectStatus = status
  },
  setErrorProjectStatus(state, { message }) {
    state.errorProjectStatus = message
  },

  setFilter(state, { filter }) {
    state.filter = filter
  },
  setFilterHandlerUpdateStatus(state, { status }) {
    state.filterHandlerUpdateStatus = status
  },
  setFilterNextTokens(state, { items }) {
    Vue.set(state.filterNextTokens, items.entityId, items.data)
  },
  setFilterDataCodes(state, { items }) {
    state.filterDataCodes = items
  },
  setFilterDataSources(state, { items }) {
    state.filterDataSources = items
  },
  setFilterDataDocumentYears(state, { items }) {
    state.filterDataDocumentYears = items
  },
  setFilterDataConfidences(state, { items }) {
    state.filterDataConfidences = items
  },
  setFilterDataEntityName(state, { items }) {
    state.filterDataEntityName = items
  },
  setFetchingFilters(state, { status }) {
    state.fetchingFilters = status
  },
  setErrorFetchingFilters(state, { message }) {
    state.errorFetchingFilters = message
  },

  setRemovingProject(state, { status }) {
    state.removingProject = status
  },
  setErrorRemovingProject(state, { message }) {
    state.errorRemovingProject = message
  },

  setPutProject(state, { status }) {
    state.putProject = status
  },
  setErrorPutProject(state, { message }) {
    state.errorPutProject = message
  },

  setSearch(state, { message }) {
    state.search = message
  },

  setPlaceholders(state, { items }) {
    state.placeholders = items
  },
  setLoadingPlaceholders(state, { status }) {
    state.loadingPlaceholders = status
  },
  setErrorPlaceholders(state, { message }) {
    state.errorPlaceholders = message
  },

  setAnalyses(state, { items }) {
    state.analyses = items
  },
  setLoadingAnalyses(state, { status }) {
    state.loadingAnalyses = status
  },
  setErrorAnalyses(state, { message }) {
    state.errorAnalyses = message
  },

  setFetchAllData(state, { status }) {
    state.fetchAllData = status
  },

  setCloseEntities(state, { status }) {
    state.closeEntities = status
  },

  setOpenEntities(state, { status }) {
    state.openEntities = status
  }
}

export default mutations
